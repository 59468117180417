import { Box } from '@mui/system';
import React, { ReactNode } from 'react';

export function IconLine(props: { icon: ReactNode; children: ReactNode }) {
  return (
    <Box display="flex" alignItems="center" style={{ height: 48, paddingLeft: 8 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ width: 32, height: 32, marginRight: 16 }}
      >
        {props.icon}
      </Box>
      <Box
        justifyContent="flex-start"
        style={{ textAlign: 'left', fontSize: 16, fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}
        flex={1}
      >
        {props.children}
      </Box>
    </Box>
  );
}
