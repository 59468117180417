import { FormControl, MenuItem, Select as MuiSelect, SelectProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function Select(props: {
  value: any;
  options: { label: ReactNode; value: any }[];
  onChange: SelectProps['onChange'];
}) {
  return (
    <FormControl sx={{ minWidth: 120, height: '100%', width: '100%' }} size="small">
      <MuiSelect value={props.value} onChange={props.onChange} variant="standard" disableUnderline displayEmpty>
        {props.options.map(({ label, value }) => (
          <MenuItem value={value}>
            <Typography>{label}</Typography>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
