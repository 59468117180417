import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  PopoverProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { useStyles } from './referralViewStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Props } from './ReferralViewTypes';
import arrowUp from '../../images/arrowUp.svg';
import region from '../../images/region.svg';
import locationError from '../../images/locationError.svg';
import clinicErrorImage from '../../images/clinicError.svg';
import clinicBlueImage from '../../images/clinicBlue.svg';
import alertIcon from '../../images/alert.svg';
import scheduledReferralIcon from '../../images/scheduledReferralIcon.svg';
import onHoldReferralIcon from '../../images/onHoldReferral.svg';
import notCompletedReferralIcon from '../../images/notCompletedReferralIcon.svg';
import discardedReferralIcon from '../../images/cancelledReferralIcon.svg';
import AttachmentItem from '../AttachmentItem/AttachmentItem';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import PatientDetails, { ListView } from './PatientDetails';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import { TabContext, TabPanel } from '@mui/lab';
import TaskList from '../TasksList/TaskList';
import CommentsList from '../CommentsList/CommentsList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAttachmentAction,
  clearActiveReferral,
  deleteReferralAction,
  fetchActiveAndArchivedReferralsAction,
  getOneReferral,
  getReferralsCountsAction,
  getReferralStatusesAction,
  removeReferralFromList,
  updateReferralAction,
  updateReferralStatusesAction,
} from '../../redux/actions/referralsActions';
import {
  getReferralTitle,
  ignoreTimezoneDate,
  userFriendlyTimestamp,
  prioritiesOptions as priorities,
  getReferralsStatusColors,
  MAIN_PAGES,
  getIdsFromArray,
  referralStatusesDefaults,
  statusesToShowAlert,
  getReferralStatusFromStringRepresentation,
  getFormattedDaysAgoText,
  getReferralStatusReasonTitle,
  statusDefaults,
  canTransitionToReadyToSchedule,
  isAfterDate,
} from '../../globalUtils/utils';
import clockBlueIcon from '../../images/clockBlue.svg';
import clockRedIcon from '../../images/clockIconRed.svg';
import { format, isAfter, isBefore, isValid } from 'date-fns';
import { ReferralStatus, RootState, StatusReason, Task, TaskType } from '../../redux/types';
import { addReferralsComment, clearTagsActionCreator, setCommentBeingEdited } from '../../redux/actions/commentActions';
import AddAttachmentsDropZone from '../AddAttachmentsModal/AddAttachmentsDropZone';
import EditClinic from '../EditClinic/EditClinic';
import { getAttachmentCategoriesAction } from '../../redux/actions/attachmentActions';
import humps from 'humps';
import EditRegion from '../EditRegion/EditRegion';
import EditAssignee from '../EditAssignee/EditAssignee';
import {
  useGetAttachmentOperations,
  useGetFilteredReferralsList,
  useLoadNewCommentFromSocketToStore,
  useReferralEnabledForSimpleStatusChange,
  useScrollIntoView,
} from '../../globalUtils/hooks';
import {
  CommentTypes,
  HIDE_REFERRAL_DECISION,
  MAX_FILE_SIZE,
  SocketEvents,
  SocketTypes,
  STATUS_CODES,
  UPLOAD_STATUSES,
} from '../../globalUtils/constants';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { validate } from 'validate.js';
import { debounce, get, uniqBy } from 'lodash';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import SimpleStatusChange from '../SimpleStatusChange/SimpleStatusChange';
import { defaultSortCompareFunction, ReferralEditFields } from '../../globalUtils/helpers';
import { CustomizedAutocomplete } from '../CustomizedAutocomplete';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TaskTransitionDialog from './TaskTransitionDialog';
import DeletedReferralView from './DeletedReferralView';
import CustomChip from '../CustomChip/CustomChip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreMenuItems from './MoreItemsMenu';
import { fetchUsersAction } from '../../redux/actions/userActions';
import SetToStatusTypeDialog from './SetToStatusTypeDialog';
import { addReferralProviderAction } from '../../redux/actions/referralProvidersAction';
import {
  editReferralLoading as editReferralLoadingAction,
  updateAttachmentFax,
} from '../../redux/actionCreators/referralActionCreators';
import { statuses } from '../../views/Referrals/Referrals';
import ReasonDialog from '../ReasonDialog/ReasonDialog';
import ReferralViewSkeleton from './ReferralViewSekeleton';
import { ReadyToSchedule } from '../ReadyToSchedule/ReadyToSchedule';
import SetToScheduled from './SetToScheduledDialog';
import MoreInfoTooltip from './MoreInfoTooltip';
import OutgoingFilesModal from './OutgoingFilesModal';
import FaxAcknowledgement from '../TasksList/FaxAcknowledgement';
import { ProviderCommunicationModal } from '../../features/ProviderCommunication/view/ProviderCommunicationModal';
import { resendFaxApi } from '../../api/faxApi';
import { getHomeCityAction } from '../../redux/actions/homeCityActions';
import { PatientMessagesComponent } from '../../features/Omnichannel/patient-messages/view/PatientMessagesComponent';
import { isOmniChannelEnabled } from '../../features/Omnichannel';
import { DateField } from '../DatePickers/DateField';
import { Select } from '../Select/Select';
import { IconLine } from '../IconLine/IconLine';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { ReferralTypeComponent } from './ReferralTypeComponent';
import { FieldBody, FieldLabel } from './FieldLabel';

const ReferralView: FC<Props> = (props) => {
  const {
    onClose,
    referralId,
    activetasksTab,
    activeReferralsTab,
    page: currentPage,
    totalTasksLoaded,
    setIsEditting,
    isEditting,
  } = props;
  const {
    attachments: attachmentsState,
    referrals: { referralStatuses },
    statusReasons: { loading: statusReasonsLoading, referralStatusReasons },
    comments: {
      clearTags,
      commentsList: rawComments,
      commentCreation: { loading: commentCreationLoading },
    },
    tasks: { taskTypes },
    referrals: {
      filters: referralFilters,
      referralStatuses: { archivedStatuses, activeStatuses },
      editReferral: { field: editReferralField, loading: editReferralLoading },
      activeReferral: { data: activeReferralData, loading: activeReferralLoading, error: activeReferralError },
      attachmentsUpload: attachmentState,
    },
    homeCities: homeCitiesState,
  } = useSelector((state: RootState) => state);
  useLoadNewCommentFromSocketToStore(referralId, SocketTypes.referral, SocketEvents.newCommentReferralComment);
  const classes = useStyles();
  const [patientDetailsLoading, setPatientDetailsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('tasks');
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [attachmentValues, setAttachmentValues] = useState<any>([]);
  const [outgoingFilesFormdata, setOutgoingFilesFormData] = useState<any>({});
  const location = useLocation();
  const [openAddAttachmentsZone, setOpenAddAttachmentsZone] = useState(false);
  const [providerCommunicationOpen, setProviderCommunicationOpen] = useState(false);
  const isOutgoingFileUpload = location.hash === '#outgoingFilesOpen';
  const [attachmentIDs, setAttachmentIDs] = useState<string[]>([]);
  const [failedAttachments, setFailedAttachments] = useState<string[]>([]);
  const [contactedChosen, setContactedChosen] = useState<boolean>(false);
  const [followedUpChosen, setFollowUpChosen] = useState<boolean>(false);
  const [publishToEMRChosen, setPublishToEMRChosen] = useState<boolean>(true);
  const [referralDetailsOpen, openReferralDetails] = useState<boolean>(true);
  const [isTaskTransitionModalOpen, setIsTaskTransitionModalOpen] = useState(false);
  const [isScheduledTaskModalOpen, setIsScheduledTaskModalOpen] = useState(false);
  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] = useState(false);
  const [statusTransitionLoading, setStatusTransitionLoading] = useState(false);
  const navigate = useNavigate();
  const [transitionValidationErrors, setTransitionValidationErrors] = useState<any>({});
  const [discardModalOpen, openDiscardModal] = useState<boolean>(false);
  const [simpleStatusChangeAnchorElement, setSimpleStatusChangeAnchorElement] = useState<any>(null);
  const [moreMenuItemsAnchorElement, setMoreMenuItemsAnchorElement] = useState<PopoverProps['anchorEl']>(null);
  const [selectedNextFollowUpDate, setSelectedNextFollowUpDate] = useState<Date | null>(
    activeReferralData.nextFollowupDate ? ignoreTimezoneDate(activeReferralData.nextFollowupDate) : null,
  );
  const [changedProvider, setChangedProvider] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [nextFollowUpDatePickerOpen] = useState<boolean>(false);
  const [nextFollowUp, setNextFollowUp] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { fromNextFollowupDate, toNextFollowupDate } = referralFilters;
  const [isDeletedReferral, setIsDeletedReferral] = useState<boolean>(false);
  const [openFollowUpDialog, setOpenFollowUpdDialog] = useState(false);
  const [commentMessage, setCommentMessage] = useState('');
  const [mentions, setMentions] = useState<any[]>([]);
  const [transitioningToOpen, setTransitioningToOpen] = useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const filteredArchivedReferralsList: any[] = useGetFilteredReferralsList(true);
  const filteredActiveReferralsList: any[] = useGetFilteredReferralsList(false);
  const [forceClose, setForceClose] = useState(false);
  const [isEditStatusMode, setIsEditStatusMode] = useState<boolean>(false);
  const [readyToScheduleDialogOpen, setReadyToScheduleDialogOpen] = useState(false);
  const [editValues, setEditValues] = useState<any>({});
  const [selectedPriority, setSelectedPriority] = useState<any>({
    label: '',
    value: 'No priority',
    inputId: 'priority',
  });
  const [selectedFilesToPublishToEMR, setSelectedFilesToPublishToEMR] = useState<any[]>([]);
  const uploadedFileNames = Object.keys(attachmentState).filter((fileName) => attachmentState[fileName].success);
  const dispatch = useDispatch();
  const { publishAttachmentToWeInfuse } = useGetAttachmentOperations();
  const [getOpenedReferralLoading, setGetOpenReferralLoading] = useState<boolean>(true);
  const [openSendFaxAck, setOpenSendFaxAck] = useState<boolean>(false);
  const [retrySendingFaxData, setRetrySendingFaxData] = useState({});
  const [isResend, setIsResend] = useState<boolean>(false);

  const rootElementRef = useRef<HTMLDivElement>(null);
  const tabPanelRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  useEffect(() => {
    switch (hash.toLowerCase()) {
      case '#comments':
        setActiveTab('comments');
        scrollToTabs();
        break;
      case '#tasks':
        setActiveTab('tasks');
        scrollToTabs();
        break;
      case '#patient-messages':
        setActiveTab('patient-messages');
        scrollToTabs();
        break;
    }

    function scrollToTabs() {
      if (tabPanelRef.current && rootElementRef.current) {
        rootElementRef.current.scrollTop = tabPanelRef.current.offsetTop;
      }
    }
  }, [hash, tabPanelRef, setActiveTab, rootElementRef, tabPanelRef.current?.offsetTop]);

  const commentsList = useMemo(() => {
    return {
      ...rawComments,
      data: uniqBy([...rawComments?.data, ...activeReferralData.comments], 'id').sort((a, b) =>
        defaultSortCompareFunction(a, b, 'createdAt'),
      ),
    };
  }, [rawComments, activeReferralData.comments]);

  const getStatusReasons = useCallback(
    (statusTitle: string): StatusReason[] => {
      const referralStatus: ReferralStatus | undefined = getReferralStatusFromStringRepresentation(
        statusTitle,
        referralStatuses,
      );
      return referralStatusReasons.filter((reason: StatusReason) => reason?.referralStatus?.id === referralStatus?.id);
    },
    [referralStatusReasons, referralStatuses],
  );

  const onContactedButtonClick = useCallback(() => {
    if (!commentCreationLoading) {
      dispatch(clearTagsActionCreator(false));
      setContactedChosen(!contactedChosen);
    }
  }, [commentCreationLoading, contactedChosen, dispatch]);

  const onFollowUpButtonClick = useCallback(() => {
    if (!commentCreationLoading) {
      dispatch(clearTagsActionCreator(false));
      setFollowUpChosen(!followedUpChosen);
    }
  }, [commentCreationLoading, dispatch, followedUpChosen]);

  const isReferralLoading: boolean = useMemo((): boolean => {
    return activeReferralLoading || statusReasonsLoading || getOpenedReferralLoading;
  }, [activeReferralLoading, getOpenedReferralLoading, statusReasonsLoading]);

  const onSelectPublishFileToEMR = (file: any, isSelected: boolean) => {
    isSelected
      ? setSelectedFilesToPublishToEMR((previousSelectedFiles) => [...previousSelectedFiles, file])
      : setSelectedFilesToPublishToEMR((previousSelectedFiles) =>
          previousSelectedFiles.filter((previousFile) => previousFile?.id !== file?.id),
        );
  };

  useEffect(() => {
    if (activeReferralData.nextFollowupDate) {
      setSelectedNextFollowUpDate(ignoreTimezoneDate(activeReferralData.nextFollowupDate));
    }
  }, [activeReferralData.nextFollowupDate]);

  const validationSchema: any = useMemo(
    () => ({
      patient: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      assignedUserId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      regionId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      clinicId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      providerId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      homeCity: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      medicationId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      taskTemplateId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
    }),
    [],
  );

  //  get first item to know if it is outgoing
  const isOutgoingOrIncomingBatch = Object.keys(attachmentState).filter(
    (fileName) => attachmentState[fileName].outgoing,
  );
  // un-uploaded files
  const formattedSelectedFiles = selectedFiles
    .filter((file) => uploadedFileNames.indexOf(file.name) < 0)
    .map((unUploadedFiles) => {
      return {
        createdAt: new Date(),
        filename: unUploadedFiles.name,
        originalFileName: unUploadedFiles.name,
        id: unUploadedFiles.name,
        rawFile: unUploadedFiles,
        outgoing: isOutgoingOrIncomingBatch.length ? isOutgoingOrIncomingBatch[0] : false,
      };
    });

  const currentReferral: any = useMemo(() => {
    const incomingFiles = formattedSelectedFiles.filter((file) => !file?.outgoing);
    const outgoingFiles = formattedSelectedFiles.filter((file) => file?.outgoing);

    return {
      ...activeReferralData,
      insurance: { ...activeReferralData.insurance, label: activeReferralData.insurance?.name },
      attachments: [...activeReferralData?.attachments, ...incomingFiles],
      outgoingAttachments: [...activeReferralData?.outgoingAttachments, ...outgoingFiles],
    };
  }, [activeReferralData, formattedSelectedFiles]);

  const getSchedulingTaskForReferral = useCallback((): Task | undefined => {
    const schedulingTaskType: TaskType | undefined = taskTypes.data.find(
      (taskType: TaskType) => taskType.name?.toLowerCase() === 'scheduling',
    );
    const schedulingTask: Task | undefined = currentReferral?.tasks?.find(
      (task: Task) => task?.taskType?.id === schedulingTaskType?.id,
    );

    return schedulingTask;
  }, [currentReferral?.tasks, taskTypes.data]);

  const orderEntryTaskCompleted: boolean = useMemo(() => {
    const orderEntryTitle: string = 'order entry';
    const orderEntryTask: Task | undefined = currentReferral?.tasks?.find(
      (task: Task) => task?.title?.toLowerCase() === orderEntryTitle?.toLowerCase(),
    );

    return orderEntryTask?.status?.name === statusDefaults.COMPLETED;
  }, [currentReferral?.tasks]);

  const initialClinicValue = useMemo(() => {
    return {
      ...currentReferral?.clinic,
      label: currentReferral?.clinic?.name,
      name: currentReferral?.clinic?.name,
    };
  }, [currentReferral?.clinic]);

  const [params] = useSearchParams();

  useScrollIntoView(params.get('taskId'), { loading: isReferralLoading });
  useScrollIntoView(params.get('commentId'), { loading: isReferralLoading });

  const isEnabledForSimpleStatusChange: boolean = useReferralEnabledForSimpleStatusChange(currentReferral);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateFailedAttachments = (name: string) => {
    setFailedAttachments((value) => [...value, name]);
  };

  const incomingAttachments = useMemo(
    () => currentReferral?.attachments?.sort((a: any, b: any) => defaultSortCompareFunction(a, b, 'createdAt')),
    [currentReferral?.attachments],
  );

  const outgoingSortedAttachments = useMemo(
    () => currentReferral?.outgoingAttachments?.sort((a: any, b: any) => defaultSortCompareFunction(a, b, 'createdAt')),
    [currentReferral?.outgoingAttachments],
  );

  const isSuccessfullyUploadedAttachment = useCallback(
    (attachment: any) => {
      return (
        !attachment?.rawFile &&
        !attachmentState[attachment.rawFile?.name]?.error &&
        !attachmentState[attachment.rawFile?.name]?.loading
      );
    },
    [attachmentState],
  );

  const successfullyUploadedIncomingAttachments: any[] = useMemo((): any[] => {
    return incomingAttachments?.filter(isSuccessfullyUploadedAttachment);
  }, [isSuccessfullyUploadedAttachment, incomingAttachments]);

  const unseenSuccessfullyUploadedIncomingAttachments: any[] = useMemo((): any[] => {
    return successfullyUploadedIncomingAttachments?.filter((attachment: any) => !attachment?.seen);
  }, [successfullyUploadedIncomingAttachments]);

  const onSelectNextFollowUpDate = useCallback(
    (newDate: Date | null) => {
      try {
        if (newDate && selectedNextFollowUpDate) {
          if (format(newDate, 'yyyy-MM-dd') === format(selectedNextFollowUpDate, 'yyyy-MM-dd')) {
            return;
          }
        }
        setSelectedNextFollowUpDate(newDate);
      } catch (e) {
        console.log(e);
      }
    },
    [selectedNextFollowUpDate],
  );

  const onAfterUpdateReferralAction = useCallback(() => {
    const isActiveTabOption: boolean = activeReferralsTab === statuses.ACTIVE;
    const referralsList: any[] = isActiveTabOption ? filteredActiveReferralsList : filteredArchivedReferralsList;
    dispatch(
      getReferralsCountsAction(referralFilters, getIdsFromArray(activeStatuses), getIdsFromArray(archivedStatuses)),
    );

    if (activeReferralsTab && referralsList.length <= 25) {
      dispatch(
        fetchActiveAndArchivedReferralsAction(
          !isActiveTabOption,
          {
            ...referralFilters,
            page: 1,
            pageSize: 26,
            sortField: 'createdAt',
            statuses: referralFilters.statuses.length
              ? referralFilters.statuses
              : getIdsFromArray(!isActiveTabOption ? archivedStatuses : activeStatuses),
          },
          getIdsFromArray(activeStatuses),
          getIdsFromArray(archivedStatuses),
        ),
      );
    }
  }, [
    activeReferralsTab,
    activeStatuses,
    archivedStatuses,
    dispatch,
    filteredActiveReferralsList,
    filteredArchivedReferralsList,
    referralFilters,
  ]);

  const onUpdateReferralsSuccess = useCallback(
    (responseData?: any) => {
      if (activeReferralsTab && archivedStatuses?.length && activeStatuses?.length) {
        onAfterUpdateReferralAction();
        dispatch(fetchUsersAction());
        dispatch(getOneReferral(currentReferral?.id, true));
      }
      setRestoreLoading(false);
    },
    [
      activeReferralsTab,
      activeStatuses?.length,
      archivedStatuses?.length,
      currentReferral?.id,
      dispatch,
      onAfterUpdateReferralAction,
    ],
  );

  const onNextFolloupDateUpdateSuccess = useCallback(
    (responseData: any) => {
      onSelectNextFollowUpDate(nextFollowUp);
      setOpenFollowUpdDialog(false);
      setCommentMessage('');
      setMentions([]);
      onUpdateReferralsSuccess(responseData);
      setNextFollowUp(null);
      setContactedChosen(false);
      setFollowUpChosen(false);
      setPublishToEMRChosen(true);
      dispatch(clearTagsActionCreator(true));
    },
    [dispatch, nextFollowUp, onSelectNextFollowUpDate, onUpdateReferralsSuccess],
  );

  const onChange = useCallback(
    (field: string, value: any, successCallback?: () => void) => {
      if (!activeReferralLoading) {
        setTransitionValidationErrors((previousErrors: any) => ({
          ...previousErrors,
          [field]: '',
        }));

        setEditValues((values: any) => ({ ...values, [field]: value }));
        const fieldsToChange = [
          'assignedUserId',
          'clinicId',
          'regionId',
          'homeCity',
          'priority',
          'referralType',
          'referralSubType',
          'intakeSpecialistId',
          'accept',
        ];

        const customOnSuccess = (data: any) => {
          successCallback && successCallback();

          switch (field) {
            case 'regionId':
              currentReferral?.insuranceId &&
                dispatch(updateReferralAction({ insuranceId: null, id: referralId }, onUpdateReferralsSuccess));

              currentReferral?.clinicId
                ? dispatch(updateReferralAction({ clinicId: null, id: referralId }, onUpdateReferralsSuccess))
                : onUpdateReferralsSuccess(data);

              break;
            case 'referralType':
              dispatch(updateReferralAction({ taskTemplateId: null, id: referralId }, onUpdateReferralsSuccess));
              break;
            default:
              onUpdateReferralsSuccess(data);
          }
        };

        if (fieldsToChange.includes(field)) {
          dispatch(updateReferralAction({ [field]: value, id: referralId }, customOnSuccess));

          const newVals: any = {};
          fieldsToChange.forEach((val) => (newVals[val] = undefined));
          setEditValues((v: any) => ({ ...v, ...newVals }));
        }

        if (field === 'nextFollowupDate') {
          dispatch(
            updateReferralAction(
              {
                [field]: value,
                id: referralId,
                message: commentMessage,
                tagUserIds: mentions.map(({ id }) => id),
                referralFollowupTag: followedUpChosen || undefined,
                patientContactTag: contactedChosen || undefined,
                commentType: commentMessage
                  ? publishToEMRChosen
                    ? CommentTypes.EXTERNAL
                    : CommentTypes.INTERNAL
                  : undefined,
              },
              (responseData: any) => {
                setEditValues({});
                setCommentMessage('');
                onNextFolloupDateUpdateSuccess(responseData);
                successCallback && successCallback();
              },
            ),
          );
        }
      }
    },
    [
      activeReferralLoading,
      commentMessage,
      contactedChosen,
      currentReferral?.clinicId,
      currentReferral?.insuranceId,
      dispatch,
      followedUpChosen,
      mentions,
      onNextFolloupDateUpdateSuccess,
      onUpdateReferralsSuccess,
      publishToEMRChosen,
      referralId,
    ],
  );

  const enqueueSnackBarError = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'default',
        className: classes['.SnackbarItem-variantDefault'],
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        autoHideDuration: 4000,
        action: () => (
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    },
    [classes, closeSnackbar, enqueueSnackbar],
  );

  const validateForNonDraftRferral = useCallback(
    (additionalProperties?: any) => {
      const validationErrors: any = validate(
        {
          ...currentReferral,
          region: currentReferral?.region?.id,
          assignee: currentReferral?.assignee?.id,
          patient: get(currentReferral, 'patient', currentReferral.firstName),
          providerId: currentReferral?.providerId || currentReferral.providerFirstName,
          ...(additionalProperties ? additionalProperties : {}),
        },
        validationSchema,
      );

      return validationErrors;
    },
    [currentReferral, validationSchema],
  );

  const delayedQuery = debounce((searchText: string) => {
    dispatch(getHomeCityAction(searchText));
  }, 500);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    delayedQuery(event.target.value);
  };

  const onChangeHomeCity = useCallback(
    (value: string) => {
      if (currentReferral?.status?.title?.toLowerCase() !== 'draft' && !value) {
        const validationErrors = validateForNonDraftRferral({ homeCity: value });
        setTransitionValidationErrors(validationErrors);

        enqueueSnackBarError('Please complete required fields');
      } else if (value !== currentReferral.homeCity) {
        onChange('homeCity', value);
      }
    },
    [currentReferral, enqueueSnackBarError, onChange, validateForNonDraftRferral],
  );

  useEffect(() => {
    dispatch(getAttachmentCategoriesAction());

    if (currentPage !== MAIN_PAGES.REFERRALS) {
      dispatch(getReferralStatusesAction());
    }
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (referralId) {
      setGetOpenReferralLoading(true);
      dispatch(
        getOneReferral(referralId, false, () => {
          setGetOpenReferralLoading(false);
        }),
      );
    }

    return () => {
      dispatch(clearActiveReferral());
    };
  }, [referralId, dispatch]);

  useEffect(() => {
    if (currentReferral.firstName || currentReferral.lastName || currentReferral.dateOfBirth) {
      setInitialValues({
        firstName: currentReferral.firstName || '',
        lastName: currentReferral.lastName || '',
        dateOfBirth: currentReferral?.dateOfBirth ? ignoreTimezoneDate(currentReferral?.dateOfBirth) : null,
      });
    }
  }, [currentReferral.firstName, currentReferral.lastName, currentReferral.dateOfBirth]);

  useEffect(() => {
    if (clearTags) {
      setContactedChosen(false);
      setFollowUpChosen(false);
      setPublishToEMRChosen(true);
    }
  }, [clearTags]);

  useEffect(() => {
    const priorityToSet = priorities.find(
      (priority) => priority?.value?.toLowerCase() === activeReferralData?.priority?.toLowerCase(),
    );

    if (priorityToSet) {
      setSelectedPriority(priorityToSet);
    } else {
      setSelectedPriority((previousValue: any) => ({
        ...previousValue,
        label: activeReferralLoading ? '' : 'No priority',
      }));
    }
  }, [activeReferralData, activeReferralLoading]);

  useEffect(() => {
    const showUploadStatusMessage = (message: string, action: ReactNode) => {
      enqueueSnackbar(message, {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        autoHideDuration: 6000,
        action,
      });
    };

    if ((attachmentIDs.length > 0 && attachmentIDs.length + failedAttachments.length) === selectedFiles.length) {
      const action = () => (
        <Button color="primary" size="medium" onClick={() => closeSnackbar()}>
          Okay
        </Button>
      );
      const message = `${attachmentIDs.length}/${selectedFiles.length} files has been successfully attached`;
      showUploadStatusMessage(message, action);
    }

    if ((failedAttachments.length > 0 && attachmentIDs.length + failedAttachments.length) === selectedFiles.length) {
      const message = `${failedAttachments.length}/${selectedFiles.length}files has not been uploaded`;

      const handleRemoveFiles = () => {
        failedAttachments.map((fileName) => {
          return handleRemoveFile(fileName);
        });
      };

      const action = () => (
        <Button color="primary" size="medium" onClick={() => handleRemoveFiles()}>
          Remove files
        </Button>
      );
      showUploadStatusMessage(message, action);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attachmentIDs,
    closeSnackbar,
    enqueueSnackbar,
    failedAttachments,
    failedAttachments.length,
    selectedFiles.length,
  ]);

  useEffect(() => {
    if (referralId === 'undefined' || referralId === 'null' || !(currentReferral?.id || activeReferralLoading)) {
      setIsDeletedReferral(activeReferralError?.statusCode === STATUS_CODES.NOT_FOUND);
    } else {
      setIsDeletedReferral(false);
    }
  }, [referralId, currentReferral, activeReferralLoading, activeReferralError?.statusCode]);

  const [searchParams] = useSearchParams();
  const currentOpenReferral = searchParams.get('referralId');
  useEffect(() => {
    if (currentOpenReferral !== currentReferral.id) {
      setOpenSendFaxAck(false);
    }
  }, [currentOpenReferral, currentReferral.id]);

  const updateAttachmentIds = (id: string) => {
    setAttachmentIDs((value) => [...value, id]);
  };

  const shouldEnableEditingOfStatusReasons: boolean = useMemo(() => {
    return [
      referralStatusesDefaults.ONHOLD.toLowerCase(),
      referralStatusesDefaults.NOTCOMPLETED.toLowerCase(),
    ].includes(currentReferral?.status?.title?.toLowerCase());
  }, [currentReferral?.status?.title]);

  const onClickEditReferralStatusButton = useCallback(() => {
    setSelectedStatus(currentReferral?.status?.title);
    setIsStatusChangeDialogOpen(true);
    setIsEditStatusMode(true);
  }, [currentReferral?.status?.title]);

  const weInfuseDob =
    currentReferral?.patient?.date_of_birth && ignoreTimezoneDate(currentReferral?.patient?.date_of_birth);
  const tempDob = currentReferral?.dateOfBirth && ignoreTimezoneDate(currentReferral?.dateOfBirth);

  const regionToFilterBy = currentReferral?.region;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialValues, setInitialValues] = useState({
    firstName: currentReferral?.patient?.first_name || '',
    lastName: currentReferral?.patient?.last_name || '',
    dateOfBirth: weInfuseDob || tempDob || null,
  });

  const saveEdit = async () => {
    setPatientDetailsLoading(true);

    const onAfterSavingProvider = (data: any) => {
      const { temporaryProvider, ...requiredValues } = editValues;
      dispatch(
        updateReferralAction({ ...requiredValues, id: referralId, providerId: data?.id }, (responseData: any) => {
          setIsEditting(false);
          onUpdateReferralsSuccess(responseData);
          setPatientDetailsLoading(false);
        }),
      );
      setEditValues({});
    };

    if (!editValues.providerId && editValues.temporaryProvider?.firstName && !activeReferralLoading) {
      dispatch(editReferralLoadingAction({ isLoading: true, field: ReferralEditFields.ANY, referralId }));
      dispatch(addReferralProviderAction({ ...editValues?.temporaryProvider }, onAfterSavingProvider));
    } else {
      const { temporaryProvider, ...requiredParams } = editValues;
      if (!activeReferralLoading) {
        dispatch(
          updateReferralAction({ ...requiredParams, id: referralId }, (responseData: any) => {
            setIsEditting(false);
            onUpdateReferralsSuccess(responseData);
            setPatientDetailsLoading(false);
          }),
        );
        setEditValues({});
      }
    }
  };

  //TO DO: Move to custom hook so as to reuse in referral view file

  const handleDrop = (acceptedFiles: any[], error: any) => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    error.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          enqueueSnackbar(
            `${acceptedFiles.length > 1 ? 'One of selected' : 'Selected'} file is too large, maximum size is ${
              MAX_FILE_SIZE.label
            }`,
            {
              anchorOrigin: { horizontal: 'right', vertical: 'top' },
              autoHideDuration: 6000,
              variant: 'error',
              action: () => (
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            },
          );
        }
      });
    });
  };

  const handleCloseAttachmentDialog = () => {
    setOpenAddAttachmentsZone(false);
    setSelectedFiles([]);
    setSelectedFilesToPublishToEMR([]);
  };

  const handleOpenAttachmentsZone = (isOutgoing: boolean) => {
    // reset data
    setSelectedFiles([]);
    setFailedAttachments([]);
    setAttachmentValues([]);
    setAttachmentIDs([]);

    if (isOutgoing) {
      return;
      // return navigate(-1);
    }
    setOpenAddAttachmentsZone(!openAddAttachmentsZone);
  };

  const handleRemoveFile = (fileName: string) => {
    const filteredFiles = selectedFiles.filter((file: any) => file.name !== fileName);
    setSelectedFiles(filteredFiles);
  };

  const formatSelectedToStr = (selected: string[]) => {
    return selected.map((categoryId) => {
      return categories.find((category) => category.id === categoryId)?.name;
    });
  };

  const handleUpload = () => {
    setOpenAddAttachmentsZone(false);
    selectedFiles?.forEach((file: any) => {
      const formData = new FormData();
      const changedFile = attachmentValues.find((attachment: any) => {
        return attachment[file.name]?.fileName === file.name;
      });

      if (changedFile) {
        changedFile[file.name]?.newFileName
          ? formData.append('file', file, changedFile[file.name]?.newFileName + `.${file?.name?.split('.').pop()}`)
          : formData.append('file', file);
        changedFile[file.name]?.categories && formData.append('categoryIds', changedFile[file.name]?.categories);
      } else {
        formData.append('file', file);
      }

      formData.append('referralId', referralId);
      isOutgoingFileUpload ? formData.append('outgoing', 'true') : formData.append('outgoing', 'false');

      const newFileName = file.name;
      dispatch(
        addAttachmentAction(
          referralId,
          formData,
          newFileName,
          (attachmentId: string) => {
            updateAttachmentIds(attachmentId);
            setSelectedFilesToPublishToEMR([]);

            if (
              selectedFilesToPublishToEMR.find(
                (selectedFile) =>
                  `${selectedFile?.fileName || selectedFile?.id}.${selectedFile?.extension || ''}` === file?.name,
              )
            ) {
              publishAttachmentToWeInfuse(referralId, attachmentId);
            }
          },
          updateFailedAttachments,
          isOutgoingFileUpload,
        ),
      );

      // close dialog from <OutgoingFilesModal /> if open
      if (isOutgoingFileUpload) {
        navigate(-1);
      }
    });
  };

  const createComment = (value: string, mentions: any[]) => {
    if (value) {
      const tagUserIds = mentions.map(({ id }) => id);
      const comment = {
        message: value,
        referralId: currentReferral?.id,
        tagUserIds,
        referralFollowupTag: followedUpChosen,
        patientContactTag: contactedChosen,
        commentType: publishToEMRChosen ? CommentTypes.EXTERNAL : CommentTypes.INTERNAL,
      };
      dispatch(
        addReferralsComment(comment, () => {
          setPublishToEMRChosen(true);
        }),
      );
    }
  };

  const onAfterAdd = (data: any) => {
    const values: any = humps.camelizeKeys(data);
    setInitialValues(values);
    setForceClose(!forceClose);
  };

  const onAfterChangeProvider = (data: any) => {
    setEditValues((values: any) => ({ ...values, temporaryProvider: data, ...{ providerId: data.id } }));
    setChangedProvider(data);
    setForceClose(!forceClose);
  };

  const isTemporaryPatient = currentReferral?.patient?.isTemporary;
  const shouldShowStatusAlert = statusesToShowAlert.includes(currentReferral?.status?.title?.toLowerCase());
  const isTemporaryProvider = currentReferral?.provider?.isTemporary;

  const alertData = (): {
    color: 'error' | 'warning' | 'info' | 'success';
    icon: any;
  } => {
    const title = currentReferral?.status?.title?.toLowerCase();
    switch (title) {
      case 'discarded':
        return {
          color: 'error',
          icon: discardedReferralIcon,
        };

      case 'scheduled':
        return {
          color: 'success',
          icon: scheduledReferralIcon,
        };

      case 'on hold':
        return {
          color: 'warning',
          icon: onHoldReferralIcon,
        };

      case 'not completed':
        return {
          color: 'info',
          icon: notCompletedReferralIcon,
        };

      default:
        return {
          color: 'error',
          icon: discardedReferralIcon,
        };
    }
  };

  const patient = {
    firstName: currentReferral?.patient?.first_name,
    lastName: currentReferral?.patient?.last_name,
    dateOfBirth: currentReferral?.patient?.date_of_birth
      ? ignoreTimezoneDate(currentReferral?.patient?.date_of_birth)
      : null,
  };

  const provider = {
    providerFirstName: currentReferral?.provider?.firstName,
    providerLastName: currentReferral?.provider?.lastName,
    providerId: currentReferral?.provider?.id,
    providerNPI: `#${currentReferral?.provider?.npi}`,
  };

  const statusesToShiftToOpen: string[] = ['Draft'];

  const discardAbleStatuses: string[] = ['draft', 'open', 'on hold', 'ready to schedule', 'not completed'];

  const onDiscardReferral = (referralId: string) => {
    dispatch(deleteReferralAction(referralId, onAfterUpdateReferralAction));
    onClose();
    openDiscardModal(false);
    navigate('/referrals');
  };

  const onDiscardNonDraft = () => {
    setSelectedStatus(referralStatusesDefaults.DISCARDED);
    setIsStatusChangeDialogOpen(true);
    openDiscardModal(false);
    setMoreMenuItemsAnchorElement(null);
  };

  const isTransitionFromDraftToOpen = (referral: any, statusTitleBeingShiftedTo: string): boolean =>
    referral?.status?.title?.toLowerCase() === 'draft' && statusTitleBeingShiftedTo.toLowerCase() === 'open';

  const shouldOpenMoveTaskModal = (statusTitleBeingShiftedTo: string) =>
    statusTitleBeingShiftedTo.toLowerCase() === 'ready to schedule';

  const shouldOpenScheduleTaskModal = (statusTitleBeingShiftedTo: string) =>
    statusTitleBeingShiftedTo.toLowerCase() === 'scheduled';

  // these statuses are static in backend, this can be updated to use the GUIDs https://flexcareinfusion.atlassian.net/wiki/spaces/FTD/pages/91422729/Workflows+and+Statuses+Solution
  const statusDialogStatuses = ['not completed', 'discarded', 'on hold'];
  const shouldOpenSetToStatusDialog = (statusTitle: string) => statusDialogStatuses.includes(statusTitle.toLowerCase());

  const updateReferralStatus = (currentReferral: any, statusTitle: string): void => {
    let validationErrors = undefined;
    const isTransitionToOpen = isTransitionFromDraftToOpen(currentReferral, statusTitle);
    if (isTransitionFromDraftToOpen(currentReferral, statusTitle)) {
      validationErrors = validateForNonDraftRferral();
    }

    setSelectedStatus(statusTitle);

    if (shouldOpenMoveTaskModal(statusTitle)) {
      setIsTaskTransitionModalOpen(true);
      setPublishToEMRChosen(true);
      dispatch(clearTagsActionCreator(false));
      return;
    }

    if (shouldOpenScheduleTaskModal(statusTitle)) {
      setIsScheduledTaskModalOpen(true);
      return;
    }

    if (shouldOpenSetToStatusDialog(statusTitle)) {
      setIsStatusChangeDialogOpen(true);
      return;
    }

    setTransitionValidationErrors(validationErrors);

    if (validationErrors) {
      enqueueSnackBarError('Please complete required fields');
    } else {
      if (isTransitionToOpen) {
        setTransitioningToOpen(true);
      }
      setRestoreLoading(true);
      dispatch(
        updateReferralStatusesAction(
          currentReferral.id,
          {
            statusId: referralStatuses?.data?.find(
              (referralStatus: any) => referralStatus?.title.toLowerCase() === statusTitle.toLowerCase(),
            )?.id,
          },
          (responseData: any) => {
            onUpdateReferralsSuccess(responseData);
            setTransitioningToOpen(false);
          },
        ),
      );
    }
  };

  const handleCloseTransitionModal = () => {
    setIsTaskTransitionModalOpen(false);
    setContactedChosen(false);
    setFollowUpChosen(false);
    setPublishToEMRChosen(true);
    dispatch(clearTagsActionCreator(true));
  };

  const handleCancelAndSave = (assignee: any) => {
    setStatusTransitionLoading(true);
    dispatch(
      updateReferralStatusesAction(
        currentReferral.id,
        {
          statusId: referralStatuses?.data?.find(
            (referralStatus: any) => referralStatus?.title.toLowerCase() === 'ready to schedule',
          )?.id,
          schedulingTaskAssigneeId: assignee?.id,
          message: commentMessage,
          tagUserIds: mentions.map(({ id }) => id),
          referralFollowupTag: followedUpChosen || undefined,
          patientContactTag: contactedChosen || undefined,
          commentType: commentMessage
            ? publishToEMRChosen
              ? CommentTypes.EXTERNAL
              : CommentTypes.INTERNAL
            : undefined,
        },
        (responseData: any) => {
          onUpdateReferralsSuccess(responseData);
          setStatusTransitionLoading(false);
          handleCloseTransitionModal();
        },
      ),
    );
  };

  const getLabelColors = (transitionErrors: any, fieldName: string) => {
    const value = get(transitionErrors, fieldName);
    return {
      background: value ? '#FEEBEE' : '',
      color: value ? 'red' : 'textSecondary',
    };
  };

  const onAfterChangeNextFollowUpDate = (
    date: any,
    fromNextFollowupDate: any,
    toNextFollowupDate: any,
    referralId: string,
  ): void => {
    if (
      isBefore(new Date(fromNextFollowupDate), new Date(date)) ||
      isAfter(new Date(toNextFollowupDate), new Date(date))
    ) {
      dispatch(removeReferralFromList({ id: referralId }));
    }
  };

  const getSanitizedDate = (date: Date): string => (isValid(date) ? format(date, 'yyyy-MM-dd') + 'T00:00:00.000Z' : '');

  const onSubmitNextFollowUpDate = () => {
    if (nextFollowUp) {
      const sanitizedDate = getSanitizedDate(nextFollowUp);
      onChange('nextFollowupDate', sanitizedDate, () => {
        onAfterChangeNextFollowUpDate(sanitizedDate, fromNextFollowupDate, toNextFollowupDate, referralId);
      });
    }
  };

  const onClickNextFollowUp = (event: any) => {
    setOpenFollowUpdDialog(true);
    setPublishToEMRChosen(true);
    dispatch(clearTagsActionCreator(false));
  };

  const onChangeComments = (comments: string, mentions: any[]) => {
    setCommentMessage(comments);
    setMentions(mentions);
  };

  const statusIcon = currentReferral?.status && getReferralsStatusColors(currentReferral?.status?.title).iconImage;

  const isDraftReferral = currentReferral?.status?.title?.toLowerCase() === 'draft';

  const onChangeTab = (e: any, value: string) => {
    setActiveTab(value);
    dispatch(setCommentBeingEdited(undefined));
    setFollowUpChosen(false);
    setContactedChosen(false);
    dispatch(clearTagsActionCreator(false));
  };

  const categories = attachmentsState?.attachmentCategories?.data;
  const onChangeNextFollowUp = (value: any) => {
    setNextFollowUp(value);
    // onSelectNextFollowUpDate(value);
  };

  const onFocus = () => {
    setDatePickerOpen(true);
  };

  const isSelectedPriority = (option: any, value: any): boolean => option?.value === value?.value;

  const statusToShowUserFriendlyTime = [
    referralStatusesDefaults.SCHEDULED,
    referralStatusesDefaults.NOTCOMPLETED,
    referralStatusesDefaults.DISCARDED,
  ];
  const shouldShowUserFriendlyDate = currentReferral?.status?.title
    ? statusToShowUserFriendlyTime?.includes(currentReferral?.status?.title?.toUpperCase())
    : false;

  const referralStatusUpdatedAt = shouldShowUserFriendlyDate
    ? userFriendlyTimestamp(currentReferral?.statusUpdatedAt, {
        roundOffLessThan2: true,
        hideTime: true,
        persistTimezone: false,
      })
    : getFormattedDaysAgoText(currentReferral?.statusUpdatedAt);

  const tasks: any[] = currentReferral?.tasks.filter((task: Task) => !canTransitionToReadyToSchedule(task));
  const isPastNextFollowupDate = isAfterDate(currentReferral?.nextFollowupDate);

  const handleCancelSendFax = () => {
    setOpenSendFaxAck(false);
    setRetrySendingFaxData({});
    setIsResend(false);
  };
  const canSendFaxAck = !tasks.some((x: any) => !!x.faxId);

  const cannotEditRegion = !(
    currentReferral?.status?.title?.toLowerCase() === referralStatusesDefaults?.DRAFT?.toLowerCase() ||
    currentReferral?.patient?.isTemporary
  );

  const canTaskTransitionToReadyToSchedule =
    !tasks.length && !isTemporaryPatient && !currentReferral?.provider?.isTemporary;
  return isReferralLoading ? (
    <ReferralViewSkeleton onClose={onClose} />
  ) : isDeletedReferral ? (
    <DeletedReferralView onClose={onClose} />
  ) : (
    <Box className={classes.root} data-id="modalReferralView" ref={rootElementRef}>
      <FaxAcknowledgement
        open={openSendFaxAck}
        isResend={isResend}
        handleCancel={handleCancelSendFax}
        referral={currentReferral}
        retrySendingFaxData={retrySendingFaxData}
      />
      <TaskTransitionDialog
        taskName="Scheduling"
        taskStatus="Ready"
        loading={statusTransitionLoading}
        open={isTaskTransitionModalOpen}
        handleAcceptTransition={handleCancelAndSave}
        handleCancelTransition={handleCloseTransitionModal}
        mentionComponentProps={{
          loading: false,
          hideSend: true,
          onSend: () => null,
          id: referralId,
          onChange: onChangeComments,
          placeholder: 'Type comment..',
          startValue: ``,
          isExpanded: true,
          disablePublishToEMR: statusTransitionLoading,
          publishToEMRSelected: publishToEMRChosen,
          onSelectPublishToEMR: () => {
            dispatch(clearTagsActionCreator(false));
            setPublishToEMRChosen(!publishToEMRChosen);
          },
        }}
        currentReferralSchedulingTask={getSchedulingTaskForReferral()}
        commentsActionProps={{
          contactedChosen,
          followedUpChosen,
          onContactedButtonClick: onContactedButtonClick,
          onFollowUpButtonClick: onFollowUpButtonClick,
        }}
      />
      <ReadyToSchedule
        isTemporaryPatient={isTemporaryPatient}
        isTemporaryProvider={isTemporaryProvider}
        tasks={tasks}
        open={readyToScheduleDialogOpen}
        onClose={() => setReadyToScheduleDialogOpen(false)}
        patient={patient}
        provider={provider}
      />
      <SetToStatusTypeDialog
        id={currentReferral?.id}
        statusId={
          getReferralStatusFromStringRepresentation(referralStatusesDefaults.NOTCOMPLETED, referralStatuses)?.id
        }
        isStatusChangeDialogOpen={
          isStatusChangeDialogOpen && selectedStatus.toUpperCase() === referralStatusesDefaults.NOTCOMPLETED
        }
        setIsStatusChangeDialogOpen={setIsStatusChangeDialogOpen}
        isTemporaryPatient={false}
        isTemporaryProvider={isTemporaryProvider}
        reasonOptions={getStatusReasons(referralStatusesDefaults.NOTCOMPLETED)}
        changeStatusToId="notCompleted"
        currentReferral={currentReferral}
        onUpdateSuccess={(responseData: any) => {
          onUpdateReferralsSuccess(responseData);
          setIsEditStatusMode(false);
        }}
        onCancel={() => setIsEditStatusMode(false)}
        isEditMode={isEditStatusMode}
      />
      <SetToStatusTypeDialog
        id={currentReferral?.id}
        statusId={getReferralStatusFromStringRepresentation(referralStatusesDefaults.ONHOLD, referralStatuses)?.id}
        isStatusChangeDialogOpen={
          isStatusChangeDialogOpen && selectedStatus.toUpperCase() === referralStatusesDefaults.ONHOLD
        }
        setIsStatusChangeDialogOpen={setIsStatusChangeDialogOpen}
        isTemporaryPatient={false}
        isTemporaryProvider={false}
        reasonOptions={getStatusReasons(referralStatusesDefaults.ONHOLD)}
        changeStatusToId="onHold"
        datePickerProps={{
          onClose: () => setDatePickerOpen(false),
          open: datePickerOpen,
          disablePast: true,
          onChange: onChangeNextFollowUp,
          value: nextFollowUp,
          label: 'Next Follow Up Date.',
        }}
        datePickerInputProps={{
          onClick: onFocus,
          onChange: () => setDatePickerOpen(false),
        }}
        nextFollowupDate={nextFollowUp ? getSanitizedDate(nextFollowUp) : nextFollowUp}
        onUpdateSuccess={(responseData: any) => {
          onAfterChangeNextFollowUpDate(
            nextFollowUp ? getSanitizedDate(nextFollowUp) : nextFollowUp,
            fromNextFollowupDate,
            toNextFollowupDate,
            referralId,
          );
          onNextFolloupDateUpdateSuccess(responseData);
          setIsEditStatusMode(false);
        }}
        commentsActionProps={{
          hideFollowUpBtn: true,
        }}
        bannerText="Please select a reason and add a comment. The next follow up date may also be set if necessary."
        bannerTitle=""
        isEditMode={isEditStatusMode}
        onCancel={() => setIsEditStatusMode(false)}
        currentReferral={currentReferral}
      />
      <SetToStatusTypeDialog
        id={currentReferral?.id}
        statusId={getReferralStatusFromStringRepresentation(referralStatusesDefaults.DISCARDED, referralStatuses)?.id}
        isStatusChangeDialogOpen={
          isStatusChangeDialogOpen && selectedStatus.toUpperCase() === referralStatusesDefaults.DISCARDED
        }
        setIsStatusChangeDialogOpen={setIsStatusChangeDialogOpen}
        isTemporaryPatient={false}
        isTemporaryProvider={false}
        reasonOptions={getStatusReasons(referralStatusesDefaults.DISCARDED)}
        changeStatusToId="discarded"
        currentReferral={currentReferral}
        onUpdateSuccess={onUpdateReferralsSuccess}
      />
      <ReasonDialog
        open={openFollowUpDialog}
        title="Change Next Followup Date"
        bannerTitle="A comment is required"
        bannerText="Please add a comment to change the next follow up date."
        commentLabel="Comment (Required)"
        mentionComponentProps={{
          loading: false,
          hideSend: true,
          showMentionTrigger: false,
          isExpanded: true,
          onSend: () => null,
          id: referralId,
          onChange: onChangeComments,
          placeholder: 'Type comment..',
          startValue: ``,
          publishToEMRSelected: publishToEMRChosen,
          onSelectPublishToEMR: () => {
            dispatch(clearTagsActionCreator(false));
            setPublishToEMRChosen(!publishToEMRChosen);
          },
          disablePublishToEMR: editReferralLoading,
        }}
        commentsActionProps={{
          contactedChosen,
          followedUpChosen,
          onContactedButtonClick: onContactedButtonClick,
          onFollowUpButtonClick: onFollowUpButtonClick,
        }}
        onCancel={() => {
          setOpenFollowUpdDialog(false);
          setSelectedNextFollowUpDate(
            activeReferralData.nextFollowupDate ? ignoreTimezoneDate(activeReferralData.nextFollowupDate) : null,
          );
          setContactedChosen(false);
          setContactedChosen(false);
          setSelectedNextFollowUpDate(null);
          setNextFollowUp(null);
          setContactedChosen(false);
          setFollowUpChosen(false);
          setPublishToEMRChosen(true);
          dispatch(clearTagsActionCreator(true));
        }}
        onSubmit={onSubmitNextFollowUpDate}
        actionButtonLabel="Change Follow-up date"
        loading={editReferralLoading}
        actionButtonProps={{
          disabled: editReferralLoading || !commentMessage || !nextFollowUp,
        }}
      >
        <DateField label="Next Follow Up Date" onChange={onChangeNextFollowUp} disablePast value={nextFollowUp} />
      </ReasonDialog>
      {isEnabledForSimpleStatusChange && (
        <Menu
          disableAutoFocusItem
          anchorEl={simpleStatusChangeAnchorElement}
          open={Boolean(simpleStatusChangeAnchorElement)}
          onClose={() => setSimpleStatusChangeAnchorElement(null)}
        >
          <SimpleStatusChange
            onClose={() => setSimpleStatusChangeAnchorElement(null)}
            referral={currentReferral}
            updateReferralStatus={updateReferralStatus}
            tooltipNode={
              !canTaskTransitionToReadyToSchedule && (
                <MoreInfoTooltip
                  onActionButtonClick={() => setReadyToScheduleDialogOpen(true)}
                  infoText={
                    <>
                      To change status to <strong>“Ready to Schedule”</strong>, please complete patient details and
                      complete {tasks?.length} necessary {tasks?.length > 1 ? ' tasks' : 'task'}.
                    </>
                  }
                />
              )
            }
          />
        </Menu>
      )}
      <ConfirmationModal
        actionButtonText="DISCARD AND DELETE"
        titleText="Are you sure you want to discard this Draft Referral?"
        contentText=" Are you sure you want to discard this Draft Referral?"
        cancelButtonText="Cancel"
        dialogProps={{
          open: discardModalOpen,
        }}
        onCancelButtonClick={() => openDiscardModal(false)}
        onActionButtonClick={() => onDiscardReferral(currentReferral.id)}
      />
      <MoreMenuItems
        anchorEl={moreMenuItemsAnchorElement}
        open={Boolean(moreMenuItemsAnchorElement)}
        onClose={() => setMoreMenuItemsAnchorElement(null)}
        referral={{
          id: referralId,
          ...currentReferral,
        }}
        restorable={currentReferral?.status?.title?.toLowerCase() === 'discarded'}
        onRestore={() => updateReferralStatus(currentReferral, 'open')}
        restoreLoading={restoreLoading}
        discardable={discardAbleStatuses.includes(currentReferral?.status?.title?.toLowerCase())}
        onDiscard={
          discardAbleStatuses.includes(currentReferral?.status?.title?.toLowerCase()) &&
          currentReferral?.status?.title?.toLowerCase() === 'draft'
            ? () => {
                openDiscardModal(true);
              }
            : onDiscardNonDraft
        }
      />
      <SetToScheduled
        isScheduledTaskModalOpen={isScheduledTaskModalOpen}
        setIsScheduledTaskModalOpen={setIsScheduledTaskModalOpen}
        referral={currentReferral}
        scheduledStatusId={
          referralStatuses?.data?.find((referralStatus: any) => referralStatus?.title.toLowerCase() === 'scheduled')?.id
        }
        loading={editReferralLoading && editReferralField === ReferralEditFields.STATUS}
      />
      <Box style={{ padding: `0 24px` }}>
        <Box className={classes.sticky}>
          <Box display="flex" justifyContent="space-between">
            <IconButton onClick={onClose} data-id="btnCloseReferralModal" disabled={isEditting}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
            <Box display="flex" justifyContent="flex-end" style={{ paddingTop: 16, gap: 16 }}>
              {statusesToShiftToOpen.includes(currentReferral?.status?.title) && (
                <Button
                  style={{
                    minWidth: 121,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    padding: 12,
                    borderRadius: 4,
                    background: editReferralLoading && transitioningToOpen ? '' : '#E3F2FD',
                  }}
                  variant={editReferralLoading && transitioningToOpen ? 'contained' : undefined}
                  disabled={transitioningToOpen && editReferralLoading}
                  onClick={() => updateReferralStatus(currentReferral, 'open')}
                >
                  <Typography
                    style={{
                      fontSize: 13,
                      textTransform: 'uppercase',
                      letterSpacing: 0.46,
                      color: editReferralLoading && transitioningToOpen ? '#6b778c' : '#0D47A1',
                    }}
                  >
                    MOVE TO OPEN
                  </Typography>
                  {editReferralLoading && transitioningToOpen && (
                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} />
                  )}
                </Button>
              )}
            </Box>
          </Box>
          <Box className={classes.chips} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              {currentReferral?.priority?.toLowerCase() === 'urgent' && (
                <Button
                  disableElevation
                  color={'error'}
                  style={{ color: '#BDBDBD', borderRadius: 32, height: 32, marginRight: 16 }}
                  variant="contained"
                  startIcon={<img alt="" src={arrowUp} />}
                >
                  <Typography style={{ color: 'white', textTransform: 'none' }}>{currentReferral?.priority}</Typography>
                </Button>
              )}
              {currentReferral?.status?.title && (
                <CustomChip
                  data-id="referralStatusBadge"
                  size="medium"
                  label={currentReferral?.status?.title}
                  sx={{}}
                  icon={<img alt="" src={statusIcon} />}
                  {...getReferralsStatusColors(currentReferral?.status?.title)}
                />
              )}
              <ReferralTypeComponent {...currentReferral} />
            </Box>
            <Box display="flex">
              <Box display="flex">
                <IconButton onClick={(event: any) => setMoreMenuItemsAnchorElement(event?.currentTarget)}>
                  <MoreHorizIcon fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: 16 }}>
            <Typography variant="h6">{getReferralTitle(currentReferral)}</Typography>
          </Box>
        </Box>
        <Box>
          <>
            <Box style={{ marginBottom: 48 }}>
              {isTemporaryPatient && (
                <Alert color="error" icon={<img src={alertIcon} alt="alert" height={20} width={20} />}>
                  <AlertTitle>Temporary Patient Selected</AlertTitle>
                  Select existing WeInfuse patient from the dropdown before changing status to “Ready to Schedule” or
                  changing "Order Entry" Task status to "Completed"
                </Alert>
              )}
              {isTemporaryProvider && (
                <Alert
                  color="error"
                  style={{ marginTop: 8 }}
                  icon={<img src={alertIcon} alt="alert" height={20} width={20} />}
                >
                  <AlertTitle>Temporary Provider Selected</AlertTitle>
                  Select existing WeInfuse provider from the dropdown before changing status to “Ready to Schedule”
                </Alert>
              )}
              {currentReferral?.isMedicationChanged && !orderEntryTaskCompleted && (
                <Alert
                  color="warning"
                  icon={<img src={alertIcon} alt="alert" height={20} width={20} />}
                  style={{ marginTop: 8 }}
                >
                  <AlertTitle>Medication change</AlertTitle>
                  The medication that was originally selected in this referral has changed. Please make sure you select
                  the correct Order from WeInfuse.
                </Alert>
              )}
              {shouldShowStatusAlert && currentReferral?.statusUpdatedByUser?.firstName && (
                <Alert
                  style={{ marginTop: 8 }}
                  color={alertData().color}
                  icon={<img src={statusIcon} alt="alert" height={20} width={20} />}
                >
                  <AlertTitle>{`${currentReferral?.status?.title} by ${currentReferral?.statusUpdatedByUser?.firstName} ${currentReferral?.statusUpdatedByUser?.lastName}`}</AlertTitle>
                  {userFriendlyTimestamp(currentReferral?.statusUpdatedAt)}
                </Alert>
              )}
              {currentReferral.region &&
                !!currentReferral.patient?.region &&
                currentReferral.region.id !== currentReferral.patient.region.id && (
                  <Alert
                    color="warning"
                    style={{ marginTop: 8 }}
                    icon={<img src={alertIcon} alt="alert" height={20} width={20} />}
                  >
                    <AlertTitle>Region mismatch</AlertTitle>
                    Selected patient belongs to a different region ({currentReferral.patient.region.name}) than the one
                    selected for the referral.
                  </Alert>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between" align-items="center">
              <Typography style={{ lineHeight: '36px' }} variant="subtitle2">
                Referral Details
              </Typography>
              <IconButton onClick={() => openReferralDetails(!referralDetailsOpen)}>
                {!referralDetailsOpen ? (
                  <KeyboardArrowDownIcon fontSize="large" />
                ) : (
                  <KeyboardArrowUpIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
            {referralDetailsOpen && (
              <>
                <Box className={classes.referralDetails}>
                  <Box display="flex" alignItems="center" className={classes.label}>
                    <Typography
                      variant="subtitle2"
                      color={getLabelColors(transitionValidationErrors, 'homeCity').color}
                      display="flex"
                    >
                      Home city
                      <Tooltip title={`Enter the referral's Home City to find the nearest Clinic.`} placement="top">
                        <span style={{ display: 'flex', alignItems: 'center', paddingLeft: 4 }}>
                          <InfoOutlinedIcon />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editHomeCity">
                    <CustomizedAutocomplete
                      id="home-city"
                      options={homeCitiesState.data}
                      loading={homeCitiesState.loading}
                      hidePopup={false}
                      value={currentReferral?.homeCity || ''}
                      onChange={(_, value) => onChangeHomeCity(value)}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      label={!currentReferral?.homeCity && !editReferralLoading ? 'Search or add new' : ''}
                      placeholder="Select or add new"
                      startAdornment={<img alt="" src={region} />}
                      freeSolo
                      selectOnFocus
                      InputProps={{
                        // on blur set the text typed as the home city value
                        onBlur: (event) => onChangeHomeCity(event.target.value),
                        onChange: handleInputChange,
                      }}
                      handleHomeEndKeys
                    />
                    {editReferralLoading && editReferralField === ReferralEditFields.HOME_CITY && (
                      <CircularProgress size={16} />
                    )}
                  </Box>

                  <Box display="flex" alignItems="center" className={classes.label}>
                    <Typography
                      variant="subtitle2"
                      color={transitionValidationErrors?.clinicId ? 'red' : 'textSecondary'}
                    >
                      Clinic
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editClinic">
                    <EditClinic
                      homeCityToFilterBy={currentReferral?.homeCity}
                      regionToFilterBy={regionToFilterBy}
                      initialValue={initialClinicValue}
                      onChange={(item) => onChange('clinicId', item?.id)}
                      disabled={
                        editReferralLoading &&
                        (editReferralField === ReferralEditFields.CLINIC ||
                          editReferralField === ReferralEditFields.REGION)
                      }
                      style={{
                        background: transitionValidationErrors?.clinicId ? '#FEEBEE' : '',
                        color: transitionValidationErrors?.clinicId ? '#FEEBEE' : '',
                      }}
                      icon={
                        <img
                          alt="ellipse"
                          src={transitionValidationErrors?.clinicId ? clinicErrorImage : clinicBlueImage}
                        />
                      }
                      showClearIcon={isDraftReferral}
                      valueIcon={
                        <img
                          alt="ellipse"
                          src={transitionValidationErrors?.clinicId ? clinicErrorImage : clinicBlueImage}
                        />
                      }
                    />
                    {editReferralLoading && editReferralField === ReferralEditFields.CLINIC && (
                      <CircularProgress size={20} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.label}>
                    <Typography
                      variant="subtitle2"
                      color={transitionValidationErrors?.regionId ? 'red' : 'textSecondary'}
                    >
                      Region
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.labelInput}
                    data-id="editRegion"
                    style={{ marginBottom: 12 }}
                  >
                    <EditRegion
                      initialValue={currentReferral?.region}
                      onChange={({ id }) => onChange('regionId', id)}
                      style={{
                        background: transitionValidationErrors?.regionId ? '#FEEBEE' : '',
                        color: transitionValidationErrors?.regionId ? '#FEEBEE' : '',
                      }}
                      icon={<img alt="" src={transitionValidationErrors?.regionId ? locationError : region} />}
                      valueIcon={<img alt="" src={transitionValidationErrors?.regionId ? locationError : region} />}
                      showClearIcon={false}
                      disabled={cannotEditRegion}
                    />
                    {editReferralLoading && editReferralField === ReferralEditFields.REGION && (
                      <CircularProgress size={20} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.label}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Status
                    </Typography>
                  </Box>
                  <Box
                    data-id="editStatus"
                    display="flex"
                    alignItems="center"
                    className={classes.labelInput}
                    style={{
                      cursor: isEnabledForSimpleStatusChange ? 'pointer' : 'default',
                      height: 48,
                      // paddingLeft: 8,
                      // marginLeft: 8,
                    }}
                  >
                    <Button
                      style={{
                        width: '100%',
                        height: 48,
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        background: Boolean(simpleStatusChangeAnchorElement) ? 'rgba(0,0,0,0.04)' : '',
                        paddingBottom: getReferralStatusReasonTitle(currentReferral) ? 24 : 6,
                      }}
                      onClick={(event: any) =>
                        isEnabledForSimpleStatusChange ? setSimpleStatusChangeAnchorElement(event?.currentTarget) : null
                      }
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ width: 32, height: 32, paddingRight: 4, marginTop: 5 }}
                      >
                        {isEnabledForSimpleStatusChange &&
                        editReferralLoading &&
                        editReferralField === ReferralEditFields.STATUS ? (
                          <CircularProgress size={20} />
                        ) : (
                          <img alt="" src={statusIcon} />
                        )}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{
                          marginLeft: 6,
                          height: 48,
                          paddingTop: 0,
                          marginTop: getReferralStatusReasonTitle(currentReferral) ? 30 : 24,
                        }}
                      >
                        <ListItem
                          style={{ paddingLeft: 4, paddingTop: 0, alignItems: 'center', paddingBottom: 0 }}
                          alignItems="flex-start"
                        >
                          <ListItemText
                            style={{
                              alignItems: 'center',
                              marginTop: getReferralStatusReasonTitle(currentReferral) ? 0 : -14,
                            }}
                            primary={
                              <Box display="flex" alignItems="center" paddingTop={0} paddingBottom={0}>
                                <Typography paddingRight={1} color="textPrimary" variant="subtitle2">
                                  {currentReferral?.status?.title}
                                </Typography>
                                <Tooltip
                                  placement="top"
                                  title={
                                    currentReferral?.statusUpdatedAt
                                      ? format(new Date(currentReferral?.statusUpdatedAt), 'LLL d, yyyy')
                                      : ''
                                  }
                                >
                                  <Typography variant="subtitle2" color="#9E9E9E">
                                    {referralStatusUpdatedAt}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            }
                            secondary={
                              <Typography color="textSecondary" variant="subtitle2">
                                {getReferralStatusReasonTitle(currentReferral)}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Button>
                  </Box>
                  {shouldEnableEditingOfStatusReasons && (
                    <>
                      <Box display="flex" className={classes.label} style={{ marginTop: -4 }} />
                      <Box display="flex" className={classes.labelInput} style={{ paddingLeft: 56, marginTop: -4 }}>
                        <Button onClick={onClickEditReferralStatusButton} style={{ marginLeft: -8, marginTop: -4 }}>
                          EDIT REASON
                        </Button>
                      </Box>
                    </>
                  )}
                  <FieldLabel>
                    <Typography
                      variant="subtitle2"
                      color={getLabelColors(transitionValidationErrors, 'assignedUserId').color}
                    >
                      Intake Specialist
                    </Typography>
                  </FieldLabel>
                  <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editSpecialist">
                    <EditAssignee
                      initialValue={currentReferral?.intakeSpecialist}
                      onChange={({ id }) => onChange('intakeSpecialistId', id)}
                      // isErrored={get(transitionValidationErrors, 'assignedUserId', false)}
                      showClearIcon={false}
                      filterOutInactiveAssignees
                    />
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.label}>
                    <Typography
                      variant="subtitle2"
                      color={getLabelColors(transitionValidationErrors, 'assignedUserId').color}
                    >
                      Assignee
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editAssignee">
                    <EditAssignee
                      initialValue={currentReferral?.assignedUser}
                      onChange={({ id }) => onChange('assignedUserId', id)}
                      isErrored={get(transitionValidationErrors, 'assignedUserId', false)}
                      showClearIcon={false}
                      filterOutInactiveAssignees
                    />
                  </Box>
                  <FieldLabel>Priority</FieldLabel>
                  <Box
                    data-id="editPriority"
                    display="flex"
                    alignItems="center"
                    className={classes.labelInput}
                    style={{ cursor: 'pointer', paddingLeft: 0, marginBottom: 0 }}
                  >
                    <CustomizedAutocomplete
                      options={priorities.map((option) => ({
                        ...option,
                        showDoneIcon: isSelectedPriority(option, selectedPriority),
                      }))}
                      hidePopup
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      onChange={(_, option) => {
                        onChange('priority', option?.value);
                        setSelectedPriority(
                          priorities.find(
                            (priority) => priority?.value?.toLowerCase() === option?.value?.toLowerCase(),
                          ),
                        );
                      }}
                      placeholder="Select"
                      startAdornment={
                        <Box
                          display="flex"
                          className={classes.icon}
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            background:
                              !editReferralLoading || editReferralField !== ReferralEditFields.PRIORITY
                                ? selectedPriority?.value?.toLowerCase() === 'urgent'
                                  ? 'red'
                                  : ''
                                : '',
                          }}
                        >
                          {selectedPriority?.value &&
                          editReferralLoading &&
                          editReferralField === ReferralEditFields.PRIORITY ? (
                            <CircularProgress size={20} />
                          ) : selectedPriority?.value?.toLowerCase() === 'urgent' ? (
                            <img alt="" src={arrowUp} />
                          ) : (
                            <ArrowForward />
                          )}
                        </Box>
                      }
                      disableClearable
                      value={selectedPriority}
                      getOptionDisabled={(option) => option?.disabled || isSelectedPriority(option, selectedPriority)}
                    />
                  </Box>
                  <FieldLabel>
                    <Typography variant="subtitle2" color={isPastNextFollowupDate ? '#D32F2F' : 'textSecondary'}>
                      Follow Up
                    </Typography>
                  </FieldLabel>
                  <FieldBody data-id="editFollowUp" onClick={onClickNextFollowUp}>
                    <Button
                      style={{
                        width: '100%',
                        height: 48,
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        background: nextFollowUpDatePickerOpen ? 'rgba(0,0,0,0.04)' : '',
                      }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center" style={{ width: 32, height: 32 }}>
                        {isPastNextFollowupDate ? (
                          <img alt="" src={clockRedIcon} />
                        ) : (
                          <img alt="" src={clockBlueIcon} />
                        )}
                      </Box>
                      <Box style={{ marginLeft: 16 }}>
                        <Typography color="textPrimary" variant="subtitle2" data-id="lblNextFollowUp">
                          Next{' '}
                          {currentReferral?.nextFollowupDate &&
                            format(
                              selectedNextFollowUpDate || ignoreTimezoneDate(currentReferral?.nextFollowupDate),
                              'LLL d, yyyy',
                            )}
                        </Typography>
                        <Typography
                          style={{ textAlign: 'left' }}
                          variant="subtitle2"
                          color="textSecondary"
                          data-id="lblLastFollowUp"
                        >
                          Last{' '}
                          {currentReferral?.lastFollowupDate &&
                            format(new Date(currentReferral?.lastFollowupDate), 'LLL d, yyyy')}
                        </Typography>
                      </Box>
                    </Button>
                  </FieldBody>
                  <FieldLabel>Inventory Distribution</FieldLabel>
                  <FieldBody>
                    <ListView
                      icon={(currentReferral?.inventoryDistribution?.detail && <InfoOutlinedIcon />) || null}
                      primaryText={currentReferral?.inventoryDistribution?.name}
                      secondaryText={currentReferral?.inventoryDistribution?.detail || ''}
                      secondaryTextProps={{ style: { fontSize: '10pt' } }}
                    />
                  </FieldBody>
                  {!HIDE_REFERRAL_DECISION && (
                    <>
                      <FieldLabel>Decision</FieldLabel>
                      <FieldBody>
                        <Select
                          value={currentReferral.accept}
                          options={[
                            { label: <IconLine icon="">None</IconLine>, value: null },
                            { label: <IconLine icon={<ThumbUpAltOutlinedIcon />}>Accept</IconLine>, value: true },
                            { label: <IconLine icon={<ThumbDownAltOutlinedIcon />}>Decline</IconLine>, value: false },
                          ]}
                          onChange={(event) => {
                            onChange('accept', event.target.value);
                          }}
                        />
                      </FieldBody>
                    </>
                  )}
                </Box>
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    Created {currentReferral?.createdAt && userFriendlyTimestamp(currentReferral?.createdAt || '')}
                  </Typography>
                  <br />
                  <Typography variant="caption" color="textSecondary">
                    Updated {currentReferral?.updatedAt && userFriendlyTimestamp(currentReferral?.updatedAt || '')}
                  </Typography>
                </Box>
              </>
            )}
          </>
        </Box>
        <PatientDetails
          referral={currentReferral}
          onChange={onChange}
          onSave={saveEdit}
          referralRegion={regionToFilterBy}
          setIsEditing={setIsEditting}
          isEditing={isEditting}
          hideAddNew={isEditting}
          errors={transitionValidationErrors}
          onAfterAdd={onAfterAdd}
          addedPatient={currentReferral?.patient}
          patient={currentReferral?.patient}
          temporaryPatient={patient}
          changedProvider={changedProvider || currentReferral?.provider}
          onAfterChangeProvider={onAfterChangeProvider}
          temporaryProvider={provider}
          forceClose={forceClose}
          isTemporaryProvider={isTemporaryProvider}
          referralType={currentReferral?.referralType?.toLowerCase()}
          {...currentReferral}
          {...initialValues}
          dateOfBirth={weInfuseDob || tempDob || null}
          editReferralLoading={editReferralLoading && isEditting}
          patientDetailsLoading={patientDetailsLoading}
        />
        <Box style={{ marginTop: 16 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" style={{ paddingRight: 8 }}>
                Incoming Files ({successfullyUploadedIncomingAttachments?.length || 0})
              </Typography>
              {unseenSuccessfullyUploadedIncomingAttachments?.filter((attachment) => !attachment?.outgoing)?.length >
                0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="space-around"
                  borderRadius={64}
                  height={20}
                  minWidth={46}
                  style={{ background: '#1976D2', padding: '0px 6.5px' }}
                >
                  <Typography fontSize={12} fontWeight={500} color="white" lineHeight={0} padding={0}>{`${
                    unseenSuccessfullyUploadedIncomingAttachments?.filter((attachment) => !attachment?.outgoing)?.length
                  } new`}</Typography>
                </Box>
              )}
            </Box>
            <Tooltip
              title="Attach Files"
              placement="top"
              classes={{
                tooltip: classes.addAttachmentTooltip,
              }}
            >
              <IconButton
                onClick={() => {
                  setOutgoingFilesFormData({});
                  handleOpenAttachmentsZone(false);
                }}
                className={classes.addIconButton}
              >
                <AddSharpIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>

          {incomingAttachments?.map((attachment: any, i: number) => {
            return (
              <AttachmentItem
                handleRemoveFile={handleRemoveFile}
                {...attachment}
                attachment={attachment}
                key={i}
                hasMarkAsRead={isSuccessfullyUploadedAttachment(attachment)}
                referralId={currentReferral?.id}
                tags={attachment.categories}
                selectValues={categories}
                percentComplete={attachmentState[attachment.rawFile?.name]?.percentComplete}
                fileUploadError={attachmentState[attachment.rawFile?.name]?.error}
                errorToolTip={attachmentState[attachment.rawFile?.name]?.errorMessage}
                fileUploadLoading={attachmentState[attachment.rawFile?.name]?.loading}
                fileUploadSuccess={attachmentState[attachment.rawFile?.name]?.success}
                isEditable
                hasWeInfusePatient={!isTemporaryPatient}
                style={{ marginBottom: i !== incomingAttachments?.length - 1 ? 6 : 0 }}
                deleteButtonDisabled={
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.SUCCESS ||
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.MANUALLY_ADDED
                }
                manualPublishDisabled={
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.SUCCESS ||
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.MANUALLY_ADDED ||
                  isTemporaryPatient
                }
              />
            );
          })}
        </Box>
        <Box style={{ marginTop: 16 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" style={{ paddingRight: 8 }}>
                Outgoing Files ({outgoingSortedAttachments?.length || 0})
              </Typography>
            </Box>
            <Tooltip
              title="Attach Files"
              placement="top"
              classes={{
                tooltip: classes.addAttachmentTooltip,
              }}
            >
              <IconButton
                onClick={() => {
                  handleOpenAttachmentsZone(true);
                  setOutgoingFilesFormData({});
                  navigate(`${location.pathname}${location.search}#outgoingFilesOpen`);
                }}
                className={classes.addIconButton}
              >
                <AddSharpIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
          {isOutgoingFileUpload && (
            <OutgoingFilesModal
              open={location.hash === '#outgoingFilesOpen'}
              referral={activeReferralData}
              handleClose={() => navigate(-1)}
              formData={outgoingFilesFormdata} // important for editing, sending, resending failed fax
              setOpenAddAttachmentsZone={setOpenAddAttachmentsZone}
              faxModals={[
                {
                  name: 'Provider Communication',
                  open: () => setProviderCommunicationOpen(true),
                },
                ...(canSendFaxAck
                  ? [
                      {
                        name: 'Fax Acknowledgment',
                        open: () => setOpenSendFaxAck(true),
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {providerCommunicationOpen && (
            <ProviderCommunicationModal
              activeReferral={activeReferralData}
              onClose={() => setProviderCommunicationOpen(false)}
            />
          )}
          <AddAttachmentsDropZone
            dialogTitle={isOutgoingFileUpload ? 'Send Files: Upload Previously Sent Files' : 'Attach Files'}
            dropZoneTitle={isOutgoingFileUpload ? 'Attach Files' : undefined}
            handleClose={handleCloseAttachmentDialog}
            handleDrop={handleDrop}
            handleUpload={handleUpload}
            handleRemoveFile={handleRemoveFile}
            open={openAddAttachmentsZone}
            selectedFiles={selectedFiles}
            categories={categories}
            formatSelectedToStr={formatSelectedToStr}
            setAttachmentValues={setAttachmentValues}
            hasWeInfusePatient={!isTemporaryPatient}
            onSelectPublishToEMR={onSelectPublishFileToEMR}
          />
          {outgoingSortedAttachments?.map((attachment: any, i: number) => {
            return (
              <AttachmentItem
                handleRemoveFile={handleRemoveFile}
                {...attachment}
                attachment={attachment}
                hasMarkAsRead={false}
                key={i}
                referralId={currentReferral?.id}
                tags={attachment.categories}
                selectValues={categories}
                percentComplete={attachmentState[attachment.rawFile?.name]?.percentComplete}
                fileUploadError={attachmentState[attachment.rawFile?.name]?.error}
                errorToolTip={attachmentState[attachment.rawFile?.name]?.errorMessage}
                fileUploadLoading={attachmentState[attachment.rawFile?.name]?.loading}
                fileUploadSuccess={attachmentState[attachment.rawFile?.name]?.success}
                isEditable
                hasWeInfusePatient={!isTemporaryPatient}
                style={{ marginBottom: i !== outgoingSortedAttachments?.length - 1 ? 6 : 0 }}
                hasRetrySendingFaxInActionMenu={
                  attachment.faxes?.[0]?.status?.name?.toLowerCase() === 'failed delivery'
                }
                deleteButtonDisabled={
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.SUCCESS ||
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.MANUALLY_ADDED
                }
                manualPublishDisabled={
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.SUCCESS ||
                  attachment?.emr?.emrUploadStatus === UPLOAD_STATUSES.MANUALLY_ADDED ||
                  isTemporaryPatient
                }
                retrySendingFax={(() => {
                  switch (attachment.faxes?.[0]?.type?.name?.toLowerCase()) {
                    case 'fax acknowledgement':
                      return () => {
                        setRetrySendingFaxData(attachment.faxes?.[0]);
                        setIsResend(true);
                        setOpenSendFaxAck(true);
                      };
                    case 'medical records request':
                      return () => {
                        setOutgoingFilesFormData((v: any) => ({
                          formType: 'Medical Records Request',
                          faxId: attachment.faxes?.[0]?.id,
                          ...attachment.faxes?.[0]?.medRecReqFormData,
                        }));
                        navigate(`${location.pathname}${location.search}#outgoingFilesOpen`);
                      };
                    default:
                      return async () => {
                        const result = await resendFaxApi(attachment.faxes[0].id);
                        dispatch(updateAttachmentFax(result.data.faxes[0]));
                      };
                  }
                })()}
              />
            );
          })}
        </Box>
        <Box ref={tabPanelRef}>
          <TabContext value={activeTab}>
            <TabList onChange={onChangeTab} variant="fullWidth" data-id="tabReferralView">
              <Tab disableRipple label={`Tasks(${currentReferral?.tasks?.length || 0})`} value="tasks" />
              <Tab disableRipple label={`Comments(${commentsList.data.length})`} value="comments" />
              {isOmniChannelEnabled && <Tab disableRipple label={`Patient Messages`} value="patient-messages" />}
            </TabList>
            <Divider />
            <TabPanel value="tasks" data-id="tasks">
              <TaskList
                avatarLoading={activeReferralLoading}
                referralStatus={currentReferral?.status}
                tasks={currentReferral?.tasks}
                referralId={currentReferral?.id}
                activeTasksTab={activetasksTab}
                totalTasksLoaded={totalTasksLoaded}
                referral={currentReferral}
                showMedicationChangedAlert={currentReferral?.isMedicationChanged && !orderEntryTaskCompleted}
              />
            </TabPanel>
            <TabPanel value="comments">
              <CommentsList
                loading={commentCreationLoading}
                id="referral-comments"
                onSend={createComment}
                comments={commentsList}
                isExpanded
                hideSecondaryActions
                disabled={activeReferralLoading}
                disablePublishToEMR={commentCreationLoading}
                onSelectPublishToEMR={() => {
                  dispatch(clearTagsActionCreator(false));
                  setPublishToEMRChosen(!publishToEMRChosen);
                }}
                publishToEMRSelected={publishToEMRChosen}
                commentActionProps={{
                  contactedChosen: contactedChosen,
                  followedUpChosen: followedUpChosen,
                  onContactedButtonClick,
                  onFollowUpButtonClick,
                }}
                hidePublishToEMR={false}
                hideCommentEditing={false}
                referral={currentReferral}
              />
            </TabPanel>
            {isOmniChannelEnabled && (
              <TabPanel value="patient-messages">
                <PatientMessagesComponent patient={currentReferral.patient} referralId={currentReferral.id} />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralView;
