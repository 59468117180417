import React, { FC, useState, useEffect, ReactNode, useCallback, useMemo, ChangeEvent } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Input,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStyles } from './ReferralStyles';
import { Props } from './ReferralTypes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CustomizedAutocomplete } from '../CustomizedAutocomplete';
import unassignedIconNewBlue from '../../images/unassignedIconNewBlue.svg';
import assigneeError from '../../images/assigneeError.svg';
import template from '../../images/template.svg';
import templateDisabled from '../../images/templateDisabled.svg';
import arrowBack from '../../images/back.svg';
import ArrowForward from '@mui/icons-material/ArrowForward';
import clinicPrimary from '../../images/clinicPrimary.svg';
import insurance from '../../images/insurance.svg';
import region from '../../images/region.svg';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import MentionComponent from '../MentionComponent/MentionComponent';
import { RootState, sharedAPITitle } from '../../redux/types';
import {
  formatComment,
  userFriendlyTimestamp,
  sortByDate,
  prioritiesOptions as priorities,
  getIdsFromArray,
  ignoreTimezoneDate,
  getReferralTypeLabel,
} from '../../globalUtils/utils';
import { clearPatientsFromSearch, searchPatientsSuccess } from '../../redux/actions/patientsActions';
import {
  createReferralAction,
  createDraftReferralAction,
  referralPayload,
  addAttachmentAction,
  getReferralCategoriesAction,
  setActiveReferral,
  fetchActiveAndArchivedReferralsAction,
  getReferralsCountsAction,
} from '../../redux/actions/referralsActions';
import { debounce, isEmpty } from 'lodash';
import AddPatient from '../AddPatient/AddPatient';
import { getDrugTiersAction } from '../../redux/actions/drugTierActions';
import { format } from 'date-fns';
import AddAttachmentsDropZone from '../AddAttachmentsModal/AddAttachmentsDropZone';
import AttachmentItem from '../AttachmentItem/AttachmentItem';
import { referralStatusesOptions, referralCategoriesOptions, MAX_FILE_SIZE } from '../../globalUtils/constants';
import { getAttachmentCategoriesAction } from '../../redux/actions/attachmentActions';
import { validate } from 'validate.js';
import PatientComponent from '../../components/PatientComponent/PatientComponent';
import ProviderComponent from '../ProviderComponent/ProviderComponent';
import EditMedication from '../EditMedicationsComponent/EditMedicationsComponent';
import EditClinic from '../EditClinic/EditClinic';
import InsuranceSelect from '../InsuranceSelect/InsuranceSelect';
import ellipsis from '../../images/arrowUp.svg';
import {
  setActiveReferralsGlobalLoading,
  setArchivedReferralsGlobalLoading,
} from '../../redux/actionCreators/referralActionCreators';
import { fetchUsersAction } from '../../redux/actions/userActions';
import EditAssignee from '../EditAssignee/EditAssignee';
import { statuses } from '../../views/Referrals/Referrals';
import EditRegion from '../EditRegion/EditRegion';
import { getInboxItem } from '../../redux/actions/inboxActions';
import { useGetInboxUpdateOperations } from '../../globalUtils/hooks';
import { updateInboxList } from '../../redux/actions/commentActions';
import { clearHomeCities, getHomeCityAction } from '../../redux/actions/homeCityActions';
import { FieldBody, FieldLabel } from '../ReferralView/FieldLabel';
import CustomizedTextField from '../CustomizedLocationField/CustomizedTextField';
import { VaccinesOutlined } from '@mui/icons-material';

const Referral: FC<Props> = (props) => {
  const { title, attachments: attachmentsProp, onClickBack, referralType, hideComments, onReferralSwitchClick } = props;
  const attachments = attachmentsProp || [];
  const classes = useStyles();
  const [formErrors, setFormErrors] = useState<any>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [attachmentValues, setAttachmentValues] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [attachmentIDs, setAttachmentIDs] = useState<string[]>([]);
  const [failedAttachments, setFailedAttachments] = useState<string[]>([]);
  const [referralData, setReferralData] = useState<any>({
    patient: { label: '' },
    provider: { label: '' },
    priority: { value: 'Normal' },
  });
  const [openAddAttachmentsZone, setOpenAddAttachmentsZone] = useState(false);
  const [clickedSaveBtn, setClickedSaveBtn] = useState<string>();
  const [hasMissingRequiredData, setHasMissingRequiredData] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isReferralPage = location.pathname?.toLowerCase() === '/referrals';
  const [isViewFromInbox, setIsViewFromInbox] = useState<boolean>(location.pathname?.toLowerCase() === '/inbox');
  const { activeTab } = props;
  const { onUpdateInboxSuccess } = useGetInboxUpdateOperations();

  const validationSchema: any = useMemo(() => {
    return {
      patient: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      assignee: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
      region: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        },
      },
    };
  }, []);

  const { activeRow } = useSelector((state: RootState) => state.inbox);

  const updateAttachmentIds = (id: string) => {
    setAttachmentIDs((value) => [...value, id]);
  };

  const updateFailedAttachments = (name: string) => {
    setFailedAttachments((value) => [...value, name]);
  };

  const {
    attachments: attachmentsState,
    referrals: {
      filters: referralFilters,
      referralStatuses: { archivedStatuses, activeStatuses },
    },
    homeCities: homeCitiesState,
  } = useSelector((state: RootState) => state);
  const attachmentState = useSelector((state: RootState) => state.referrals.attachmentsUpload);
  const uploadedFileNames = Object.keys(attachmentState).filter((fileName) => attachmentState[fileName].success);

  const categories = attachmentsState?.attachmentCategories?.data;

  const formatSelectedToStr = (selected: string[]) => {
    const selectedCategoriesNamesArray = selected.map((categoryId) => {
      return categories.find((category) => category.id === categoryId)?.name;
    });

    return selectedCategoriesNamesArray;
  };

  const formattedSelectedFiles = selectedFiles
    .filter((file) => uploadedFileNames.indexOf(file.name) < 0)
    .map((unUploadedFiles) => {
      return {
        createdAt: new Date().toString(),
        filename: unUploadedFiles.name,
        originalFileName: unUploadedFiles.name,
        id: unUploadedFiles.name,
        rawFile: unUploadedFiles,
        url: 'example.com',
      };
    });

  const existingAndNewAttachments = [...attachments, ...formattedSelectedFiles];

  let { referrals, taskTemplates } = useSelector((state: RootState) => state);
  const saveReferralLoading =
    referrals?.loading ||
    referrals.draftReferrals.loading ||
    referrals.archivedReferralsList?.loading ||
    referrals?.activeReferralsList?.loading;

  const draftStatusID =
    referrals.referralStatuses?.data?.find((datum: sharedAPITitle) => datum.title === referralStatusesOptions.draft)
      ?.id || '';
  const notCompletedCategoryId =
    referrals.referralCategories?.data?.find(
      (datum: sharedAPITitle) => datum.title === referralCategoriesOptions.notCompleted,
    )?.id || '';
  const activeCategoryId =
    referrals.referralCategories?.data?.find(
      (datum: sharedAPITitle) => datum.title === referralCategoriesOptions.active,
    )?.id || '';
  const openStatusId =
    referrals.referralStatuses?.data?.find((datum: sharedAPITitle) => datum.title === referralStatusesOptions.open)
      ?.id || '';

  const taskTemplatesData = taskTemplates.data?.map((taskTemplate) => ({
    ...taskTemplate,
    label: taskTemplate.name,
    inputId: 'task',
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDrugTiersAction());
    dispatch(getReferralCategoriesAction());
    dispatch(getAttachmentCategoriesAction());

    return () => {
      //clear searched patients
      dispatch(searchPatientsSuccess([]));
      //clear searched cities
      dispatch(clearHomeCities());
    };
  }, [dispatch]);

  useEffect(() => {
    setIsViewFromInbox(location.pathname?.toLowerCase() === '/inbox');
  }, [location]);

  const isReferral = referralType?.referralType?.toLowerCase() === 'referral';
  const hideAddNew = !isReferral;
  const handleChange = (fieldName: string, value: any) => {
    if (fieldName === 'medicationId' && (!value || value === null)) {
      setReferralData({
        ...referralData,
        [fieldName]: value,
        task: undefined,
      });
    } else {
      const task = isReferral
        ? { ...value?.referralTaskTemplate, label: value?.referralTaskTemplate?.name, inputId: 'task' }
        : { ...value?.renewalTaskTemplate, label: value?.referralTaskTemplate?.name, inputId: 'task' };

      const templatesBasedOnMedication = taskTemplatesData.filter((template) => {
        return template?.name?.toLowerCase() === task.name?.toLowerCase();
      });

      setReferralData({
        ...referralData,
        ...(fieldName === 'medicationId' && value && { task }),
        [fieldName]: value,
      });

      if (fieldName === 'medicationId') {
        setTaskFromMedication({
          defaultTask: task.name,
          templatesBasedOnMedication: templatesBasedOnMedication,
        });
      }
    }

    setFormErrors((previousErrors: any) => ({
      ...previousErrors,
      [fieldName]: '',
    }));
  };

  useEffect(() => {
    const task = isReferral
      ? {
          ...referralData?.medicationId?.referralTaskTemplate,
          label: referralData?.medicationId?.referralTaskTemplate?.name,
          inputId: 'task',
        }
      : {
          ...referralData?.medicationId?.renewalTaskTemplate,
          label: referralData?.medicationId?.referralTaskTemplate?.name,
          inputId: 'task',
        };

    const templatesBasedOnMedication = taskTemplatesData.filter((template) => {
      return template?.name?.toLowerCase() === task.name?.toLowerCase();
    });

    setTaskFromMedication({
      defaultTask: '',
      templatesBasedOnMedication: templatesBasedOnMedication,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralData?.task]);

  useEffect(() => {
    if (isEmpty(referralData.region) && !isEmpty(referralData.clinic)) {
      const regionFromClinic = { ...referralData?.clinic?.region, label: referralData?.clinic?.region?.name };
      setReferralData({
        ...referralData,
        region: regionFromClinic,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralData?.clinic]);

  useEffect(() => {
    if (
      !isEmpty(referralData.region) &&
      referralData?.region?.id &&
      !isEmpty(referralData.clinic) &&
      referralData.region?.id !== referralData.clinic?.region?.id
    ) {
      setReferralData({
        ...referralData,
        clinic: null,
      });
    }
  }, [referralData]);

  useEffect(() => {
    // if region is cleared or changed, clear patient and also searched ones
    if (
      referralData?.region?.weinfuseGroupId !== referralData?.patient?.region?.weinfuseGroupId &&
      referralData?.patient?.region?.weinfuseGroupId !== undefined
    ) {
      setReferralData({
        ...referralData,
        patient: {
          first_name: '',
          last_name: '',
        },
      });
      dispatch(clearPatientsFromSearch());
    }

    //when clearing region, and there's insurance selected then clear the insurance value
    if (
      referralData?.region?.id !== referralData?.insuranceId?.regionId &&
      referralData?.insuranceId?.regionId !== undefined &&
      referralData?.insuranceId?.id?.toString()?.toLowerCase() !== 'other'
    ) {
      setReferralData({
        ...referralData,
        insuranceId: undefined,
      });
    }
  }, [dispatch, referralData]);

  useEffect(() => {
    const task = isReferral
      ? {
          ...referralData?.medicationId?.referralTaskTemplate,
          label: referralData?.medicationId?.referralTaskTemplate?.name,
          inputId: 'task',
        }
      : {
          ...referralData?.medicationId?.renewalTaskTemplate,
          label: referralData?.medicationId?.renewalTaskTemplate?.name,
          inputId: 'task',
        };

    const templatesBasedOnMedication = taskTemplatesData.filter((template) => {
      return template?.name?.toLowerCase() === task.name?.toLowerCase();
    });

    setTaskFromMedication({
      defaultTask: task.name,
      templatesBasedOnMedication: templatesBasedOnMedication,
    });

    setReferralData({
      ...referralData,
      ...{ task },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralType]);

  const getData = useCallback((): referralPayload => {
    return {
      clinicId: referralData?.clinic?.id,
      // inboxId: activeRow?.id,
      patientId: referralData?.patient?.id,
      patient: referralData?.patient,
      regionId: referralData?.region?.id,
      provider: referralData?.provider,
      providerId: referralData?.provider?.id,
      drugTierId: referralData?.medicationId?.drugTierId,
      medicationId: referralData?.medicationId?.id,
      ...referralType,
      categoryId: '', // changed accordingly either draft or active while submitting
      taskTemplateId: referralData?.task?.id,
      priority: referralData?.priority?.value || 'Normal',
      homeCity: referralData?.homeCity,
      assignedUserId: referralData?.assignee?.id,
      attachmentIds: attachmentIDs,
      ...(referralData?.insuranceId?.id !== 'Other' && { insuranceId: referralData?.insuranceId?.id }),
      primaryInsuranceCarrier: referralData?.primaryInsuranceCarrier,
      dose: referralData?.dose,
    };
  }, [attachmentIDs, referralData, referralType]);

  const handleNavigateToReferral = useCallback(
    (id?: string) => {
      navigate(`/referrals?referralId=${id}`);
      closeSnackbar();
    },
    [closeSnackbar, navigate],
  );

  const showSnackBar = useCallback(
    (id?: string, errorMessage?: string) => {
      enqueueSnackbar(errorMessage ? errorMessage : 'Referral has been created', {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        autoHideDuration: 6000,
        variant: 'default',
        className: errorMessage ? classes['.SnackbarItem-variantDefault'] : '',
        action: () => (
          <>
            {!errorMessage ? (
              <Button color="primary" size="medium" onClick={() => handleNavigateToReferral(id)}>
                Open
              </Button>
            ) : null}
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    },
    [classes, closeSnackbar, enqueueSnackbar, handleNavigateToReferral],
  );

  const onReferralCreateAction = useCallback(() => {
    dispatch(fetchUsersAction());
    const isArchivedTab: boolean = activeTab !== statuses.ACTIVE;

    dispatch(
      getReferralsCountsAction(referralFilters, getIdsFromArray(activeStatuses), getIdsFromArray(archivedStatuses)),
    );

    dispatch(
      fetchActiveAndArchivedReferralsAction(
        isArchivedTab,
        {
          ...referralFilters,
          sortField: 'createdAt',
          page: 1,
          pageSize: 26,
          statuses: getIdsFromArray(isArchivedTab ? archivedStatuses : activeStatuses),
        },
        getIdsFromArray(activeStatuses),
        getIdsFromArray(archivedStatuses),
        () => {
          const inboxId: string | undefined = activeRow?.id;
          dispatch(isArchivedTab ? setArchivedReferralsGlobalLoading(false) : setActiveReferralsGlobalLoading(false));

          if (isViewFromInbox && inboxId) {
            dispatch(
              getInboxItem(inboxId, (responseData: any) => {
                onUpdateInboxSuccess(responseData);
                dispatch(updateInboxList(responseData));
              }),
            );
          }
        },
      ),
    );
  }, [
    activeRow?.id,
    activeStatuses,
    activeTab,
    archivedStatuses,
    dispatch,
    isViewFromInbox,
    onUpdateInboxSuccess,
    referralFilters,
  ]);

  const onAfterCreateReferral = useCallback(
    (buttonClicked: string) => (id?: string, errorMessage?: string, responseData?: any) => {
      onReferralCreateAction();
      if (id && buttonClicked === 'createAndView') {
        dispatch(setActiveReferral(responseData));
        handleNavigateToReferral(id);
      } else {
        showSnackBar(id, errorMessage);
      }
    },
    [dispatch, handleNavigateToReferral, onReferralCreateAction, showSnackBar],
  );

  const validateReferralDataToBeSaved = (referralData: any, validationSchema: any) => {
    const validationErrors: any = validate(
      {
        ...referralData,
        region: referralData?.region?.id,
        assignee: referralData?.assignee?.id,
        patient: referralData?.patient?.first_name,
      },
      validationSchema,
    );

    setFormErrors(validationErrors);
  };

  const getAdditionalProperties = (activeRow: any, isViewFromInbox: boolean): any => {
    const additionalProperties = isViewFromInbox ? { inboxId: activeRow?.id } : {};

    return additionalProperties;
  };

  const handleSaveDraftReferral = useCallback(() => {
    validateReferralDataToBeSaved(referralData, validationSchema);

    if (isEmpty(referralData?.patient?.first_name) || !referralData?.region?.id || !referralData?.assignee?.id) {
      setHasMissingRequiredData(!hasMissingRequiredData);
      showSnackBar('', 'Please complete required fields');
    } else {
      const data: referralPayload = getData();
      dispatch(
        createDraftReferralAction(
          {
            ...data,
            ...getAdditionalProperties(activeRow, isViewFromInbox),
            statusId: draftStatusID,
            categoryId: notCompletedCategoryId,
          },
          dispatch,
          (id?: string, errorMessage?: string) => {
            showSnackBar(id, errorMessage);
            onReferralCreateAction();
          },
        ),
      );
    }
  }, [
    activeRow,
    dispatch,
    draftStatusID,
    getData,
    hasMissingRequiredData,
    isViewFromInbox,
    notCompletedCategoryId,
    onReferralCreateAction,
    referralData,
    showSnackBar,
    validationSchema,
  ]);

  const handleSaveReferral = useCallback(
    (clickedBtn: string, locationToNavigateTo?: string) => {
      validateReferralDataToBeSaved(referralData, validationSchema);
      const data: referralPayload = getData();
      setClickedSaveBtn(clickedBtn);

      dispatch(
        createReferralAction(
          {
            ...data,
            ...getAdditionalProperties(activeRow, isViewFromInbox),
            statusId: openStatusId,
            locationToNavigateTo,
            categoryId: activeCategoryId,
          },
          onAfterCreateReferral(clickedBtn),
          navigate,
          dispatch,
        ),
      );
    },
    [
      activeCategoryId,
      activeRow,
      dispatch,
      getData,
      isViewFromInbox,
      navigate,
      onAfterCreateReferral,
      openStatusId,
      referralData,
      validationSchema,
    ],
  );

  const handleDrop = (acceptedFiles: any[], error: any) => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    error.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === 'file-too-large') {
          enqueueSnackbar(
            `${acceptedFiles.length > 1 ? 'One of selected' : 'Selected'} file is too large, maximum size is ${
              MAX_FILE_SIZE.label
            }`,
            {
              anchorOrigin: { horizontal: 'right', vertical: 'top' },
              autoHideDuration: 6000,
              variant: 'error',
              action: () => (
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            },
          );
        }
      });
    });
  };

  //TO DO: Move to custom hook so as to reuse in referral view file
  useEffect(() => {
    const showUploadStatusMessage = (message: string, action: ReactNode) => {
      enqueueSnackbar(message, {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        autoHideDuration: 6000,
        action,
      });
    };

    if ((attachmentIDs.length > 0 && attachmentIDs.length + failedAttachments.length) === selectedFiles.length) {
      const action = () => (
        <Button color="primary" size="medium" onClick={() => closeSnackbar()}>
          Okay
        </Button>
      );
      const message = `${attachmentIDs.length}/${selectedFiles.length} files has been successfully attached`;
      showUploadStatusMessage(message, action);
    }
    if ((failedAttachments.length > 0 && attachmentIDs.length + failedAttachments.length) === selectedFiles.length) {
      const message = `${failedAttachments.length}/${selectedFiles.length}files has not been uploaded`;

      const handleRemoveFiles = () => {
        failedAttachments.map((fileName) => {
          return handleRemoveFile(fileName);
        });
      };

      const action = () => (
        <Button color="primary" size="medium" onClick={() => handleRemoveFiles()}>
          Remove files
        </Button>
      );
      showUploadStatusMessage(message, action);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attachmentIDs,
    closeSnackbar,
    enqueueSnackbar,
    failedAttachments,
    failedAttachments.length,
    selectedFiles.length,
  ]);

  const handleUpload = () => {
    //close dialog
    setOpenAddAttachmentsZone(false);
    // start upload
    selectedFiles?.forEach((file: any) => {
      const formData = new FormData();
      const changedFile = attachmentValues.find((attachment: any) => {
        return attachment[file.name]?.fileName === file.name;
      });

      if (changedFile) {
        changedFile[file.name]?.newFileName
          ? formData.append('file', file, changedFile[file.name]?.newFileName + `.${file?.name?.split('.').pop()}`)
          : formData.append('file', file);
        changedFile[file.name]?.categories && formData.append('categoryIds', changedFile[file.name]?.categories);
      } else {
        formData.append('file', file);
      }

      const newFileName = file.name;
      formData.append('outgoing', 'false');

      dispatch(
        addAttachmentAction(props.id || '', formData, newFileName, updateAttachmentIds, updateFailedAttachments),
      );
    });
  };

  const handleRemoveFile = (fileName: string) => {
    const filteredFiles = selectedFiles.filter((file: any) => file.name !== fileName);
    // remove from backend
    setSelectedFiles(filteredFiles);
  };

  const handleOpenAttachmentsZone = () => {
    setOpenAddAttachmentsZone(!openAddAttachmentsZone);
    // clear values on each open
    setSelectedFiles([]);
    setFailedAttachments([]);
    setAttachmentValues([]);
    setAttachmentIDs([]);
  };

  const createComment = (value: string, mentions: any[]) => {
    const tagUserIds = mentions.map(({ id }) => id);
    const comment = {
      message: value,
      inboxId: 'XXXXXXXXXXXX',
      tagUserIds,
    };
    console.log(comment);
    // dispatch(addComment(comment));
  };
  const { loading: commentsLoading } = useSelector((state: RootState) => state.comments.commentsList);
  const { loading: creationLoading } = useSelector((state: RootState) => state.comments.commentCreation);
  const comments: any = [];
  const sortedComments = comments?.sort((a: any, b: any) => sortByDate(a.createdAt, b.createdAt));

  const hasAllRequiredData =
    !isEmpty(referralData?.patient?.first_name) &&
    referralData?.region?.id &&
    referralData?.assignee?.id &&
    referralData?.clinic?.id &&
    referralData?.medicationId?.id &&
    !isEmpty(referralData?.provider?.firstName) &&
    referralData?.task?.id &&
    referralData?.homeCity &&
    referralData?.insuranceId?.id;

  const [taskFromMedication, setTaskFromMedication] = useState<{
    defaultTask: string;
    templatesBasedOnMedication: any;
  }>({
    defaultTask: '',
    templatesBasedOnMedication: [],
  });
  const [openPatientAdd, setOpenPatientAdd] = useState<boolean>(false);
  const [addedPatient, setAddedPatient] = useState();
  const [addedProvider, setAddedProvider] = useState();
  const [forceClose, setForceClose] = useState(false);
  const [forceCloseProvider, setForceCloseProvider] = useState(false);

  const onAfterAdd = (value: any) => {
    const newPatient = {
      ...value,
      searchString: value.first_name,
      label: value.first_name + ' ' + value.last_name,
      secondaryLabel: value.date_of_birth && format(ignoreTimezoneDate(value.date_of_birth), 'MM/dd/yyyy'),
    };
    setAddedPatient(newPatient);

    handleChange('patient', { ...value, inputId: 'patient' });
    setOpenPatientAdd(false);
    setForceClose(!forceClose);
  };

  const onAfterChangeProvider = (data: any) => {
    const newProvider = {
      ...data,
      searchString: data.firstName,
      label: data.firstName + ' ' + data.lastName,
      secondaryLabel: data.npi,
    };
    setAddedProvider(newProvider);
    setOpenPatientAdd(false);
    setForceCloseProvider(!forceCloseProvider);
    handleChange('provider', { ...data, inputId: 'patient' });
  };

  const handleCloseAttachmentDialog = () => {
    setOpenAddAttachmentsZone(false);
    setSelectedFiles([]);
  };

  const isSelectedPriority = (option: any, value: any): boolean => option?.value === value?.value;

  const priority = getData()?.priority;

  const startAdornment = (
    <Box className={classes.adornment} display="flex" alignItems="center" justifyContent="center">
      <img alt="" src={insurance} />
    </Box>
  );

  const delayedQuery = debounce((searchText: string) => {
    dispatch(getHomeCityAction(searchText));
  }, 500);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    delayedQuery(event.target.value);
  };

  const doseChangeHandler = debounce(
    (event: ChangeEvent<HTMLInputElement>) => handleChange('dose', Number(event.target.value)),
    500,
  );

  return (
    <Box className={classes.root} data-id="modalNewReferral">
      <AddPatient onAfterAdd={onAfterAdd} open={openPatientAdd} onOpenChange={setOpenPatientAdd} />
      <Box display="flex" justifyContent="space-between">
        <IconButton onClick={onClickBack} data-id="btnBack">
          <img alt="" src={arrowBack} />
        </IconButton>
        <Box>
          <Button
            variant="contained"
            disabled={!hasAllRequiredData || saveReferralLoading}
            onClick={() => handleSaveReferral('createOnly')}
          >
            Create
            {(referrals?.archivedReferralsList?.loading ||
              referrals.activeReferralsList?.loading ||
              referrals?.loading) &&
              clickedSaveBtn === 'createOnly' && (
                <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={16} />
              )}
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            variant="contained"
            disabled={!hasAllRequiredData || saveReferralLoading}
            onClick={() => handleSaveReferral('createAndView', !isReferralPage ? '/referrals' : undefined)}
          >
            Create & View
            {(referrals?.archivedReferralsList.loading ||
              referrals.activeReferralsList.loading ||
              referrals?.loading) &&
              clickedSaveBtn === 'createAndView' && (
                <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={16} />
              )}
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            variant="outlined"
            disabled={saveReferralLoading}
            onClick={handleSaveDraftReferral}
          >
            Save as Draft
            {referrals?.draftReferrals?.loading && <CircularProgress size={16} />}
          </Button>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" data-id="referralType">
          {getReferralTypeLabel(referralType.referralSubType || '')}{' '}
        </Typography>
        <IconButton onClick={onReferralSwitchClick}>
          <KeyboardArrowDownIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="caption" color="textSecondary" data-id="referalTitle">
          {title ? `From ${title}` : 'New Referral'}
        </Typography>
      </Box>
      <Box className={classes.details}>
        <Typography variant="subtitle2">Referral Details</Typography>
        <Box className={classes.box}>
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary" display="flex">
              Home city
              <Tooltip title={`Enter the referral’s Home City to find the nearest Clinic.`} placement="top">
                <span style={{ display: 'flex', alignItems: 'center', paddingLeft: 4 }}>
                  <InfoOutlinedIcon />
                </span>
              </Tooltip>
            </Typography>
          </Box>
          <Box className={classes.labelInput}>
            <CustomizedAutocomplete
              id="home-city"
              options={homeCitiesState.data}
              loading={homeCitiesState.loading}
              hidePopup={false}
              value={referralData?.homeCity || ''}
              onChange={(_, value) => handleChange('homeCity', value)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              label={!referralData?.homeCity ? 'Search or add new' : ''}
              placeholder="Select or add new"
              startAdornment={<img alt="" src={region} />}
              freeSolo
              selectOnFocus
              InputProps={{
                // on blur set the text typed as the home city value
                onBlur: (event) => handleChange('homeCity', event.target.value),
                onChange: handleInputChange,
              }}
              handleHomeEndKeys
            />
          </Box>
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Clinic
            </Typography>
          </Box>
          <Box style={{ marginRight: 16, width: 'calc(60% - 16px)' }} className={classes.labelInput} data-id="clinic">
            <EditClinic
              regionToFilterBy={referralData?.region}
              onChange={(value) => handleChange('clinic', value)}
              icon={<img alt="" src={clinicPrimary} />}
              valueIcon={<img alt="" src={clinicPrimary} />}
              homeCityToFilterBy={referralData?.homeCity}
              showExpandMore
            />
          </Box>

          <Box className={classes.label}>
            <Typography variant="body2" color={formErrors?.region ? 'red' : 'textSecondary'}>
              Region<span className={formErrors?.region ? classes.redColor : undefined}>*</span>
            </Typography>
          </Box>
          <Box style={{ width: 'calc(60% - 16px)', marginRight: 16 }} className={classes.labelInput} data-id="region">
            <EditRegion
              label="Select"
              isErrored={formErrors?.region}
              onChange={(value) => handleChange('region', value)}
              showExpandMore
              initialValue={referralData?.region}
            />
          </Box>
          <Box className={classes.label}>
            <Typography variant="body2" color={formErrors?.assignee ? 'red' : 'textSecondary'}>
              Assignee<span className={formErrors?.assignee ? classes.redColor : undefined}>*</span>
            </Typography>
          </Box>
          <Box style={{ marginRight: 16, width: 'calc(60% - 16px)' }} className={classes.labelInput} data-id="assignee">
            <EditAssignee
              showExpandMore
              isErrored={!!formErrors?.assignee}
              label="Select"
              onChange={(value) => handleChange('assignee', value)}
              icon={
                <img
                  alt=""
                  style={{ color: '#1976D2' }}
                  src={formErrors?.assignee ? assigneeError : unassignedIconNewBlue}
                />
              }
              filterOutInactiveAssignees
            />
          </Box>
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Priority
            </Typography>
          </Box>
          <Box className={classes.labelInput}>
            <CustomizedAutocomplete
              data-id="priority"
              options={priorities.map((option) => ({
                ...option,
                showDoneIcon: isSelectedPriority(option, referralData?.priority),
              }))}
              hidePopup={false}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              onChange={(_, value) => handleChange('priority', value)}
              defaultValue={priorities[0]}
              placeholder="Select"
              startAdornment={
                priority === 'Normal' ? (
                  <Box display="flex" className={classes.icon} alignItems="center" justifyContent="center">
                    <ArrowForward />
                  </Box>
                ) : (
                  <Box className={classes.ellipsis}>
                    <img src={ellipsis} alt="" />
                  </Box>
                )
              }
              disableClearable={true}
              getOptionDisabled={(option) => option?.disabled || isSelectedPriority(option, referralData?.priority)}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.details}>
        <Typography variant="subtitle2">Patient</Typography>
        <Box className={classes.box}>
          <Box className={classes.label}>
            <Typography variant="body2" color={formErrors?.patient ? 'red' : 'textSecondary'}>
              Patient<span className={formErrors?.patient ? classes.redColor : undefined}>*</span>
            </Typography>
          </Box>
          <PatientComponent
            handleChange={handleChange}
            selectedPatientValue={referralData.patient}
            onAfterAdd={onAfterAdd}
            addedPatient={addedPatient || referralData.patient}
            formErrors={formErrors}
            hideAddNew={hideAddNew}
            forceClose={forceClose}
            referralRegion={referralData?.region}
          />
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Medication
            </Typography>
          </Box>
          <Box
            style={{ marginRight: 16, width: 'calc(60% - 16px)' }}
            className={classes.labelInput}
            data-id="medication"
          >
            <EditMedication
              initialValue={{}}
              onChange={(value) => handleChange('medicationId', value)}
              label={!referralData?.medicationId?.label ? 'Select' : ''}
            />
          </Box>
          <FieldLabel>Dose</FieldLabel>
          <FieldBody class={classes.labelInput}>
            <CustomizedTextField startAdornment={<VaccinesOutlined />} onChange={doseChangeHandler} />
          </FieldBody>
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Task Template
            </Typography>
          </Box>
          <Box
            style={{ marginRight: 10, width: 'calc(60% - 10px)' }}
            className={classes.labelInput}
            data-id="taskTemplate"
          >
            <Tooltip
              placement="bottom"
              classes={{ popper: classes.popper, tooltipPlacementBottom: classes.popper, tooltip: classes.tooltip }}
              title={!referralData?.medicationId ? `"Medication" is required to select "Task Template"` : ''}
            >
              <span style={{ width: '100%' }}>
                <CustomizedAutocomplete
                  options={(taskFromMedication.templatesBasedOnMedication || taskTemplatesData).map((option: any) => ({
                    ...option,
                    showDoneIcon: option?.name === (referralData?.task?.name || taskFromMedication.defaultTask),
                  }))}
                  loading={taskTemplates.loading}
                  hidePopup={false}
                  isOptionEqualToValue={(option, value) => option?.name === value}
                  label={!referralData?.task?.label ? 'Select' : ''}
                  onChange={(_, value) => handleChange('task', value)}
                  placeholder="Select"
                  startAdornment={<img alt="" src={referralData?.medicationId ? template : templateDisabled} />}
                  disabled={!referralData?.medicationId}
                  style={{
                    cursor: !referralData?.medicationId ? 'not-allowed' : 'pointer',
                    width: '98.5%',
                    marginRight: 6,
                  }}
                  inputstyle={{
                    cursor: !referralData?.medicationId ? 'not-allowed' : 'pointer',
                    color: !referralData?.medicationId ? '#616161E5' : '',
                  }}
                  labelStyle={{
                    color: !referralData?.medicationId ? '#616161E5' : '#1976D2',
                    cursor: !referralData?.medicationId ? 'not-allowed' : '',
                    fontSize: 16,
                  }}
                  disableClearable={!referralData?.task}
                  value={referralData?.task?.name || taskFromMedication.defaultTask}
                  getOptionDisabled={(option) =>
                    option?.disabled || option?.name === (referralData?.task?.name || taskFromMedication.defaultTask)
                  }
                />
              </span>
            </Tooltip>
          </Box>
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Provider
            </Typography>
          </Box>
          <ProviderComponent
            clinicId={referralData.clinic?.id}
            handleChange={handleChange}
            selectedProviderValue={referralData?.provider}
            onAfterChangeProvider={onAfterChangeProvider}
            changedProvider={addedProvider}
            forceClose={forceCloseProvider}
          />
          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Insurance
            </Typography>
          </Box>
          <Box className={classes.labelInput} data-id="insurance-dropdown">
            <InsuranceSelect
              onChange={(value) => handleChange('insuranceId', value)}
              referralRegion={referralData?.region || {}}
              initialValue={referralData?.insuranceId}
            />
          </Box>

          <Box className={classes.label}>
            <Typography variant="body2" color="textSecondary">
              Insurance details
            </Typography>
          </Box>
          <Box className={classes.labelInput} data-id="insurance">
            <Input
              onChange={(e) => handleChange('primaryInsuranceCarrier', e.target.value)}
              placeholder={'Enter here'}
              disableUnderline
              startAdornment={startAdornment}
              className={classes.input}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.attachments} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">Attachments</Typography>
        <Tooltip title="Attach Files" classes={{ tooltip: classes.addAttachmentTooltip }} placement="top">
          <IconButton onClick={handleOpenAttachmentsZone} className={classes.addIconButton}>
            <AddSharpIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      <AddAttachmentsDropZone
        handleClose={handleCloseAttachmentDialog}
        handleDrop={handleDrop}
        handleUpload={handleUpload}
        handleRemoveFile={handleRemoveFile}
        open={openAddAttachmentsZone}
        selectedFiles={selectedFiles}
        categories={categories}
        formatSelectedToStr={formatSelectedToStr}
        setAttachmentValues={setAttachmentValues}
        hidePublishToWeInfuse
      />
      <Box>
        {existingAndNewAttachments?.map((file, i) => {
          return (
            <AttachmentItem
              handleRemoveFile={handleRemoveFile}
              key={i}
              tag="Referral"
              {...file}
              tags={file.categories}
              percentComplete={attachmentState[file.rawFile?.name]?.percentComplete}
              fileUploadError={attachmentState[file.rawFile?.name]?.error}
              errorToolTip={attachmentState[file.rawFile?.name]?.errorMessage}
              fileUploadLoading={attachmentState[file.rawFile?.name]?.loading}
              hideDeleteButton
              hidePublishToWeInfuse
              hideManuallyPublishToEMR
            />
          );
        })}
      </Box>
      {!hideComments && (
        <>
          <Box style={{ marginTop: 16 }} className={classes.paddingBox}>
            <Typography variant="subtitle2">Comments </Typography>
            <Box className={classes.commentsTitle}>
              <MentionComponent
                loading={creationLoading}
                onSend={createComment}
                id={'xx'}
                placeholder="Type comment.."
              />
            </Box>

            <Box className={classes.comments}>
              <List>
                {sortedComments.map((comment: any, i: number) => (
                  <ListItem style={{ marginBottom: 20 }} key={i}>
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <Avatar />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center">
                          <Typography variant="subtitle2">{`${comment.user.firstName} ${comment.user.lastName}`}</Typography>
                          <Typography style={{ marginLeft: 16 }} variant="caption" color="textSecondary">
                            {userFriendlyTimestamp(comment.updatedAt)}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Typography variant="body2">
                          <span dangerouslySetInnerHTML={{ __html: formatComment(comment.message) }} />
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Box display="flex" justifyContent="center">
                {commentsLoading && <CircularProgress size={32} />}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Referral;
